import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractMissionTransferComponent } from "../../../../abstract/core/abstract-mission-transfer.component";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiMissionBranchService } from "../../../../api/custom/services/api-mission-branch.service";

@Component({
  selector: "app-mission-branch-transfer",
  templateUrl: "../../../core/dialogs/mission-transfer/mission-transfer.component.html",
})
export class MissionBranchTransferComponent extends AbstractMissionTransferComponent implements OnInit, OnDestroy {
  @OwInject(ApiMissionBranchService) apiMissionBranchService: ApiMissionBranchService;

  ngOnInit() {
    this.condition = this.data.condition;

    if (this.data.condition.product) {
      this.productOrCurrency = this.productPlayerService.getProduct(this.data.condition.product);
    }

    this.clearForm();
    this.subscribePlayer();
  }

  missionHandOver() {
    const amount = this.form.value.amount;
    this.loading = true;
    console.log(this.condition);

    const patchMissionHandOver = {
      branch_mission_id: this.condition["branch_mission_id"],
      amount,
    };

    this.apiMissionBranchService.patchMissionBranchHandOver(patchMissionHandOver)
      .subscribe(() => {
        this.loading = false;
        this.reload = true;
        this.close();
      }, () => {
        this.loading = false;
      });
  }


  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
