import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractCurrencyExchangeComponent } from "../../../../abstract/core/abstract-currency-exchange.component";

@Component({
  selector: "app-core-currency-exchange",
  templateUrl: "./currency-exchange.component.html",
})
export class CoreCurrencyExchangeComponent extends AbstractCurrencyExchangeComponent implements OnInit, OnDestroy {
  @ViewChild("inputCurrencyAmount") inputCurrencyAmount: ElementRef;
  ngOnInit() {
    this.clearForm();
    this.subscribePrimary();
  }
  checkInput(event: InputEvent) {
    if (this.inputCurrencyAmount.nativeElement.value > this.max) {
      this.inputCurrencyAmount.nativeElement.value = this.max;
      this.form.controls['amount'].setValue(this.max);
    }
    if(this.form.controls["amount"].value <1){
      this.inputCurrencyAmount.nativeElement.value = 0;
      this.form.controls["amount"].setValue(0);
    }
  }
  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
