<div class="global-dialog ow-dialog window-a primary no-transparent">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <loading></loading>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">KODY Z NAGRODAMI</div>
  </div>

  <div class="container loading-opacity">
    <div class="code-wrapper">
      <span>Wpisz kod i odbierz nagrodę. Kody można dostać <br />w zdrapkach oraz przy innych okazjach. Bądź czujny!</span>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="input-wrapper">
          <input type="text" class="code" id="code" formControlName="code" required />
          <button class="base primary" [disabled]="formGroup.invalid || isLoading" (click)="onSubmit()">OK</button>
        </div>
      </form>
    </div>

    <div class="history-label">
      <span>Historia kodów:</span>
      <div class="separator"></div>
    </div>

    <ow-perfect-scrollbar [scrollGradient]="rewardsHistory">
      <div class="history-wrapper">
        <div class="row" *ngFor="let reward of rewardsHistory">
          <div class="date">{{ reward.activated_at | date: "y-MM-dd" }}</div>
          <div class="content">{{ reward.code }}</div>
          <div class="prize">
            <m-ui-currency *ngIf="reward.prize.currency_id" [item]="reward.prize" [lack]="false" [stockView]="STOCK_VIEW.J"></m-ui-currency>
            <m-ui-product *ngIf="reward.prize.product_id" [stockView]="STOCK_VIEW.J" [item]="reward.prize" [lack]="false"></m-ui-product>
          </div>
        </div>
      </div>
    </ow-perfect-scrollbar>
  </div>
  <div class="no-history-data" *ngIf="rewardsHistory?.length === 0">Brak dostępnych kodów</div>
</div>
