import { Routes } from "@angular/router";

import { LoginComponent } from "../../base/core/components/login/login.component";
import { LandingComponent } from "../../base/custom/components/landing/landing.component";
import { CustomLoginComponent } from "../../base/custom/components/login/login.component";
import { OauthConfirmComponent } from "../../base/custom/components/oauth-confirm/oauth-confirm.component";
import { AUTH_ROUTING_PATH } from "../core/routing-auth.const";

export const CUSTOM_ROUTING_AUTH_CHILDREN: Routes = [
  {
    path: "",
    component: (() => {
      return window.location.origin.toLowerCase().includes("localhost") ? LoginComponent : CustomLoginComponent;
    })(),
  },
  {
    path: "login",
    pathMatch: "full",
    component: CustomLoginComponent,
  },
  {
    path: "oauth2/confirm/:token",
    component: OauthConfirmComponent,
  },
  {
    path: "landing",
    component: LandingComponent,
  },
];
