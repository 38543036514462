import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiBusinessRewardCodesService {
  constructor(private apiService: ApiService) {}

  getRewardsHistory(): Observable<RewardCode[]> {
    return this.apiService.get(`reward/codes/history`);
  }

  activateRewardCode(code: string) {
    return this.apiService.request("PATCH", `reward/codes/${code}/activate`);
  }
}

export interface RewardCode {
  code: string;
  prize: { currency_id?: number; product_id?: number; amount: number };
  activated_at: string;
}
