import { Component, OnInit } from '@angular/core';
import { AbstractBasicActionsComponent } from '../../../../abstract/custom/abstract-basic-actions.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { OwDate } from '../../../../../../../../core/utility/ow-date.class';

@Component({
  selector: 'app-basic-actions',
  templateUrl: './basic-actions.component.html',
})
export class BasicActionsComponent extends AbstractBasicActionsComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;

  ngOnInit() {
    this.getBasicActionDetails(new OwDate().momentDate.format('YYYY-MM-DD'));
  }

}
