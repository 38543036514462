import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiMissionService } from "../../../../api/core/services/api-mission.service";
import {
  GetMissionBranchPlayersRequest,
} from "../../../../api/custom/interfaces/requests/get-mission-branch-players-request.interface";
import { ApiMissionBranchService } from "../../../../api/custom/services/api-mission-branch.service";
import { EVENT_DIALOGS_NAMES_MISSION_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { BranchMission } from "../../../../interfaces/custom/branch-mission.interface";

@Component({
  selector: "app-mission-branch",
  templateUrl: "./mission-branch.component.html",
})
export class MissionBranchComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiMissionService) apiMissionService: ApiMissionService;
  @OwInject(ApiMissionBranchService) apiMissionBranchService: ApiMissionBranchService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<MissionBranchComponent>;
  @OwInject(MAT_DIALOG_DATA) data: {
    branch_mission_id: number;
  };

  mission: BranchMission;
  missionBranchPlayers: GetMissionBranchPlayersRequest[];
  prizes;
  STOCK_VIEW = STOCK_VIEW;
  isDetailsView = false;
  isAfterTransfer: boolean;
  CONTRIBUTION_CURRENCY_ID = 2;
  contributionCurrency;

  ngOnInit(): void {
    this.getBranchMission();
  }

  toggleDetailsView() {
    this.isDetailsView = !this.isDetailsView;
  }

  getBranchMission() {
    this.apiMissionBranchService.getMissionBranch({
      branch_mission_id: this.data.branch_mission_id,
    })
      .subscribe((mission) => {
        if (this.isAfterTransfer && mission.status == 1) {
          this.matDialogRef.close();
        } else {
          this.mission = mission;
          this.contributionCurrency = this.mission.currency_prizes.find(c => c.currency_id == this.CONTRIBUTION_CURRENCY_ID);
          this.getBranchMissionPlayers();
        }
      });
  }

  getBranchMissionPlayers() {
    this.apiMissionBranchService.getMissionBranchPlayers({
        branch_mission_id: this.data.branch_mission_id,
    })
      .subscribe((resp) => {
        this.missionBranchPlayers = resp;
      });
  }

  openMissionTransfer() {
    const condition = {
      target: this.mission.target,
      value: this.mission.handed_over,
      currency: this.mission.currency,
      product: this.mission.product,
      branch_mission_id: this.mission.branch_mission_id,
    };
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MISSION_CUSTOM.MISSION_BRANCH_TRANSFER,
      config: {
        data: {
          condition,
        },
      },
      callback: ({ reload }: { reload?: boolean } = {}) => {
        if (reload) {
          this.isAfterTransfer = true;
          this.ngOnInit();
        }
      },
    });
  }
}
