import { Directive, Injectable, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { GetGameRankResultsResponse } from "../../api/custom/interfaces/response/get-game-rank-results.response.interface";
import { ApiCoreGameRanksService } from "../../api/custom/services/api-game-ranks.service";
import { RankGame } from "../../interfaces/custom/rank-game.interface";

@Directive()
@Injectable()
export abstract class AbstractRankDetailsGameComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractRankDetailsGameComponent>;
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: {
    rankId: number;
    rankGroup: number;
  };
  STOCK_VIEW = STOCK_VIEW;
  rank: RankGame;
  rankResults: GetGameRankResultsResponse;

  ngOnInit() {
    this.getGameRank();
  }

  getGameRank() {
    this.apiCoreGameRanksService
      .getGameRank({
        rank_edition_id: this.data.rankId,
      })
      .subscribe(
        resp => {
          this.rank = resp;
          this.getGameRanksResults();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  getGameRanksResults() {
    this.apiCoreGameRanksService
      .getGameRanksResults({
        rank_edition_id: this.rank.rank_edition_id,
      })
      .subscribe(
        resp => {
          this.rankResults = resp;
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }
  close() {
    this.matDialogRef.close();
  }
}
