export const pwaImage =
  '<svg version="1.0" xmlns="http://www.w3.org/2000/svg"\n' +
  ' width="360.000000pt" height="200.000000pt" viewBox="0 0 360.000000 200.000000"\n' +
  ' preserveAspectRatio="xMidYMid meet">\n' +
  "\n" +
  '<g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"\n' +
  'fill="#000000" stroke="none">\n' +
  '<path d="M929 1866 c-87 -31 -141 -83 -181 -172 -18 -43 -21 -100 -7 -166 3\n' +
  "-9 -26 -49 -69 -97 -80 -88 -82 -96 -35 -151 l25 -30 -44 0 c-69 0 -82 -16\n" +
  "-128 -155 -23 -69 -39 -132 -36 -140 3 -8 24 -26 48 -41 24 -15 44 -28 46 -29\n" +
  "2 -2 -44 -156 -102 -343 -58 -187 -106 -347 -106 -355 0 -8 11 -26 25 -41 l24\n" +
  "-26 591 0 c630 0 617 -1 673 48 11 10 17 11 17 3 0 -6 15 -20 33 -31 31 -19\n" +
  "52 -20 773 -20 693 0 742 1 757 18 8 9 18 28 22 42 10 40 -200 1015 -225 1048\n" +
  "-55 71 -240 358 -240 372 0 8 16 57 35 109 40 108 42 126 16 141 -32 16 -186\n" +
  "13 -201 -5 -7 -8 -17 -15 -22 -15 -5 0 -16 7 -24 15 -23 23 -165 21 -194 -3\n" +
  "-22 -18 -24 -17 -68 5 -96 49 -221 16 -284 -75 l-31 -46 -16 24 c-79 120 -204\n" +
  "157 -322 97 l-51 -27 -20 25 -19 25 -195 0 c-116 0 -203 -4 -215 -10 -14 -8\n" +
  "-31 -7 -64 5 -55 18 -137 19 -186 1z m156 -27 c22 -6 52 -20 67 -30 33 -24 36\n" +
  "-24 40 4 3 21 8 22 103 25 55 2 142 0 193 -3 l93 -7 -6 -56 c-4 -32 -9 -66\n" +
  "-12 -77 -2 -11 11 4 31 32 99 143 274 152 363 20 42 -62 53 -104 53 -199 0\n" +
  "-129 -55 -241 -145 -294 -154 -90 -316 18 -333 222 -4 55 6 131 23 177 6 15 0\n" +
  "17 -53 17 -71 0 -59 26 -97 -220 -14 -91 -28 -177 -31 -192 -4 -22 -2 -28 12\n" +
  "-28 81 0 179 -80 209 -171 26 -76 33 -177 23 -299 -27 -333 -154 -544 -357\n" +
  "-596 -147 -37 -269 24 -321 163 -51 137 -33 332 57 606 5 15 -2 17 -51 17 -31\n" +
  "0 -56 -1 -56 -2 -2 -30 -192 -753 -200 -763 -17 -20 -292 -22 -307 -2 -7 8 26\n" +
  "129 107 390 l117 377 -51 0 c-29 0 -57 5 -63 11 -13 13 58 237 79 251 7 4 60\n" +
  "8 118 8 100 1 103 1 67 14 -55 20 -116 74 -117 104 0 8 30 45 67 83 l66 69 30\n" +
  "-30 c48 -49 131 -62 144 -23 2 6 -27 23 -65 37 -38 15 -80 38 -93 52 -62 66 0\n" +
  "236 106 291 64 34 122 41 190 22z m1209 -10 c41 -11 136 -97 136 -123 0 -8\n" +
  "-23 -34 -52 -59 l-52 -45 -32 34 c-36 39 -56 42 -84 14 -25 -25 -28 -126 -5\n" +
  "-170 31 -59 83 -63 111 -7 9 18 18 34 19 35 2 2 33 -18 69 -45 42 -31 65 -55\n" +
  "65 -68 1 -33 -56 -121 -91 -143 -143 -88 -314 30 -348 241 -23 144 28 279 122\n" +
  "326 48 23 84 26 142 10z m280 -16 c2 -10 7 -38 11 -63 13 -88 16 -88 49 -4 18\n" +
  "43 38 79 44 78 7 -1 43 -2 82 -2 l69 -2 -30 -82 c-60 -160 -67 -125 59 -316\n" +
  "62 -92 112 -171 112 -175 0 -4 -42 -7 -94 -7 l-94 0 -53 85 c-29 47 -56 88\n" +
  "-60 91 -15 9 -20 -41 -10 -86 21 -92 22 -91 -71 -88 l-83 3 -37 265 c-20 146\n" +
  "-37 277 -38 293 0 27 0 27 69 27 58 0 70 -3 75 -17z m-688 -738 c6 -71 12\n" +
  "-125 13 -120 19 121 41 237 46 245 7 12 202 14 209 2 3 -5 12 -53 21 -108 24\n" +
  "-150 31 -153 39 -19 3 66 10 123 14 127 5 5 116 7 247 6 l239 -3 44 -108 c25\n" +
  "-59 46 -106 48 -105 1 2 -4 41 -12 88 -23 142 -32 130 101 130 l114 0 9 -33\n" +
  "c28 -102 203 -960 198 -972 -4 -12 -30 -15 -144 -15 -100 0 -142 4 -149 13 -6\n" +
  "6 -34 74 -63 150 -29 75 -54 136 -56 135 -1 -2 7 -64 17 -138 11 -74 17 -141\n" +
  "14 -148 -3 -9 -41 -12 -140 -12 -111 0 -137 3 -141 15 -4 8 -23 198 -44 422\n" +
  "-21 225 -38 406 -39 403 -1 -3 -19 -191 -40 -417 -21 -227 -40 -415 -42 -417\n" +
  "-3 -3 -63 -8 -134 -12 -111 -6 -132 -4 -143 9 -7 9 -24 103 -38 218 -14 112\n" +
  "-26 204 -27 206 -2 1 -15 -91 -30 -204 -15 -114 -32 -212 -38 -220 -16 -18\n" +
  '-262 -19 -276 0 -9 11 -84 892 -83 980 l0 38 128 -3 127 -3 11 -130z"/>\n' +
  '<path d="M1160 1733 c-1 -5 -18 -28 -40 -52 l-38 -44 -32 26 c-20 18 -43 27\n' +
  "-65 27 -44 0 -54 -17 -18 -32 115 -47 132 -58 148 -89 55 -105 -53 -302 -184\n" +
  "-337 -32 -9 -26 -10 44 -11 44 -1 86 -5 93 -9 10 -7 9 -26 -7 -98 -17 -79 -17\n" +
  "-86 -3 -64 54 81 130 142 205 166 l42 13 -52 0 c-44 1 -53 4 -53 19 0 10 18\n" +
  "108 40 217 22 109 40 200 40 202 0 2 -27 3 -60 3 l-61 0 7 35 c3 19 3 35 0 35\n" +
  '-3 0 -6 -3 -6 -7z"/>\n' +
  '<path d="M1730 1645 c-32 -34 -41 -141 -16 -180 34 -51 96 -41 122 20 49 119\n' +
  '-31 240 -106 160z"/>\n' +
  '<path d="M1291 949 c-32 -52 -71 -254 -71 -365 0 -122 53 -94 84 46 37 163 46\n' +
  '338 19 347 -7 3 -21 -10 -32 -28z"/>\n' +
  "</g>\n" +
  "</svg>";
