<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- BOTTOM CUSTOM INFO -->
  <div class="bottom-bar">
    <div class="bar-button">
      <button class="primary base" (click)="filterBranch()">
        Filtruj
      </button>
    </div>
  </div>

<!--   TOP BAR BASE-->
    <div *ngIf="data?.rank?.name " class="top-bar base">
      <!-- RANK NAME -->
      <div class="title">
        {{ data?.rank?.name ?? '' }}
      </div>
    </div>

  <!-- RANK DETAILS -->
  <div class="container">
    <ow-perfect-scrollbar scrollGradient>
      <div class="branch" *ngFor="let branch of branchList" (click)="selectBranch(branch)">
        <div class="checkbox">
          <i class="fas fa-check" *ngIf="branch.selected"></i>
        </div>
        <p>
          {{ branch.branchName }}
        </p>
      </div>
    </ow-perfect-scrollbar>
  </div>
</div>
