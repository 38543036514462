import { Currency } from "../../../../../../core/interfaces/currency";
import { Task } from "../../interfaces/custom/task.interface";

export function setPaidPrizesHelper(task: Task): Currency[] {
  const allPrizes: Currency[] = [];

  task.paid_scope_prizes.forEach(scope_currency => {
    if (scope_currency.amount && scope_currency.currency_id) {
      allPrizes.push(scope_currency);
    }
  });
  task.paid_progressive_prizes.forEach(progressive_currency => {
    if (progressive_currency.amount && progressive_currency.currency_id) {
      allPrizes.push(progressive_currency);
    }
  });

  const allIds: number[] = [];

  allPrizes.forEach(ele => {
    if (!allIds.includes(ele.currency_id)) {
      allIds.push(ele.currency_id);
    }
  });

  const summedPrizes: Currency[] = [];

  allIds.forEach(id => {
    let amount = 0;
    allPrizes.forEach(prizes => {
      if (id === prizes.currency_id) {
        amount += prizes.amount;
      }
    });
    summedPrizes.push({ currency_id: id, amount: amount });
  });

  const allIds2: number [] = [];

  task.progressive_currency_prizes.forEach(progressive_currency => {
    allIds2.push(progressive_currency.currency_id);
  });

  allIds2.forEach(ids => {
    let id_exist = false;
    summedPrizes.forEach(sum => {
      if (sum.currency_id === ids) {
        id_exist = true;
      }
    });
    if (!id_exist) {
      summedPrizes.push({ currency_id: ids, amount: 0 });
    }
  });

  if (summedPrizes.length) {
    return summedPrizes;
  } else {
    return [];
  }
}
