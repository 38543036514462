import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { ApiService } from "../../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { PlayerAttributesService } from "../../../services/custom/player-attributes.service";

@Injectable({
  providedIn: "root",
})
export class ApiPlayerAttributesService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
    private playerAttributesService: PlayerAttributesService
  ) {}

  getPlayerProductAttributes(playerId?: number) {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`player/${playerId}/product-attributes`).pipe(
      tap(resp => {
        this.playerAttributesService.playerProductAttributes = resp;
      })
    );
  }
}
