export const MOCK_UP_TASKS = [
  {
    task_id: 1,
    qa_id: 72,
    slides:
      [{
        id: 0,
        url: 'https://euromisja.pl/api/files/download/16d26dc2-14b9-477e-81c9-ee754b2d5fd0/zadanie_makietowe_slajd_startowy.png',
        description: 'Kliknij strzałkę, aby przejść do kolejnego slajdu.',
        is_interactive: false
      },
        {
          id: 1,
          url: 'https://euromisja.pl/api/files/download/fa129fae-f94f-45d4-a988-2a54d010a1fe/ekran01bez.png',
          description: '<p><b>ZADANIE MAKIETOWE: FerrNadek – Zgłaszanie produktów do bieżącej przeceny</b></p>' +
            '<p>Zapoznaj się z instrukcjami na kolejnych planszach i postępuj zgodnie z poleceniami. Za prawidłowe przejście ' +
            'przez szkolenie i rozwiązanie końcowego quizu „Przeceny” otrzymasz nagrodę - 4000 cegieł.</p>' +
            'Przeklikuj się przez slajdy za pomocą strzałek. W przypadku niektórych slajdów, aby przejść dalej, ' +
            'poprosimy Cię o kliknięcie w określony obszar na makiecie. Powodzenia!',
          is_interactive: false
        },
        {
          id: 2,
          url: 'https://euromisja.pl/api/files/download/bd64476f-354b-4f0f-b570-48c14421a95e/ekran01.png',
          description: '<p>Wybierz ikonę PM66, aby otworzyć program FerrNandek.</p>' +
            'Aby wejść w zgłoszenie produktu do przeceny, należy kliknąć w ikonę „Przeceny”.',
          is_interactive: false
        },
        {
          id: 3,
          url: 'https://euromisja.pl/api/files/download/019757cc-9117-457e-9c1e-b60f5cc7b04d/ekran02a.png',
          description: 'Rozpoczęcie procesu zgłaszania produktów do przecen należy rozpocząć od kliknięcia w ikonę „Propozycja przecen”.',
          is_interactive: false
        },
        {
          id: 4,
          url: 'https://euromisja.pl/api/files/download/305a378b-79ab-4598-a98d-24b1624271c2/ekran03.png',
          description: '<p>W oknie propozycji przecen, należy wprowadzić kod lub indeks towaru.</p>' +
            'Produkty możesz dodać poprzez skanowanie lub wpisanie kodu towaru.',
          is_interactive: false
        },
        {
          id: 5,
          url: 'https://euromisja.pl/api/files/download/28729c71-9c8d-4701-9711-7e9dde657cc1/ekran04.png',
          description: '<p>Świetnie, udało się wyświetlić produkt zgłoszony do przeceny.</p>' +
            '<b>ZADANIE:</b> Gdzie po zeskanowaniu/wpisaniu kodu i wyświetleniu się produktu jest widoczna informacja, ' +
            'że produkt jest towarem z alokacji DEAL? Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 6,
          url: 'https://euromisja.pl/api/files/download/0a3bd0ee-481f-49be-9a62-7b0f1ecb67bd/ekran05.png',
          description: '<p>Dobrze Ci idzie! Aby dodać propozycję przeceny, należy wpisać ilość produktów, które chcemy przecenić. ' +
            'Następnie należy podać datę ważności produktu. W przypadku rodzaju przeceny - system zaproponuje go automatycznie. ' +
            'Aby go zmienić wystarczy przesunąć się na właściwy rodzaj Kierownicza - SAP  (tu na przykładzie - SAP)</p>' +
            '<b>ZADANIE:</b> W którym miejscu możemy ustawić termin ważności produktu? Aby  przejść do następnego slajdu, ' +
            'kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 7,
          url: 'https://euromisja.pl/api/files/download/6aaea737-113e-4831-b166-6d3120927ec6/ekran06.png',
          description: '<p>Świetnie! Na kalendarzu możesz ustawić datę ważności produktu.</p>' +
            'Pamiętaj, bez wskazania daty system nie przepuści Cię dalej.',
          is_interactive: false
        },
        {
          id: 8,
          url: 'https://euromisja.pl/api/files/download/8d2a2bdc-3c58-4750-be22-15e6ad0024d4/ekran07.png',
          description: '<p>Brawo, mamy już wszystkie potrzebne informacje, aby zgłosić produkt do przeceny.</p>' +
            '<b>ZADANIE:</b> Jak zapisać propozycję przeceny? Aby przejść dalej, kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 9,
          url: 'https://euromisja.pl/api/files/download/5c793fe2-a964-4db1-b9a2-f11c5f76706a/ekran22.png',
          description: 'W zależności od tego jaki rodzaj przeceny (Kierownicze/SAP) ustawiliśmy, pojawi się ona w odpowiedniej zakładce.',
          is_interactive: false
        },
        {
          id: 10,
          url: 'https://euromisja.pl/api/files/download/1601e5d0-fb83-42a1-b54e-b02e28995b02/ekran10.png',
          description: '<p>Tym razem dodajmy propozycję przeceny kierowniczej. Postępuj podobnie jak w przypadku przeceny SAP, ' +
            ' podając ilość produktu do zwrotu oraz datę ważności.</p>' +
            '<b>ZADANIE:</b> Gdzie można podejrzeć warunki zwrotu? Aby przejść dalej, kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 11,
          url: 'https://euromisja.pl/api/files/download/7c7970ff-3050-42ef-9a60-87698afc188b/ekran11.png',
          description: 'Świetnie! Udało Ci się wyświetlić szczegółowe warunki zwrotu produktu.',
          is_interactive: false
        },
        {
          id: 12,
          url: 'https://euromisja.pl/api/files/download/f36901aa-89c4-4ede-af39-f01a5b26c5bd/ekran12.png',
          description: 'Jeśli po wprowadzeniu ilości pojawi się taki komunikat, oznacza to, że podana ilość przekracza ' +
            'stan magazynowy. Mimo to, system pozwoli zapisać podaną ilość i przejść dalej.',
          is_interactive: false
        },
        {
          id: 13,
          url: 'https://euromisja.pl/api/files/download/aa5bdc7e-8e9b-456d-9b73-682ebc06f286/ekran13.png',
          description: '<p>Co się stanie jeśli w trakcie wprowadzania propozycji przeceny naciśniesz przycisk wyjścia z okna?</p>' +
            'Jeśli naciśniesz NIE, pozostaniesz w koszyku przecen. Jeśli naciśniesz TAK, wyświetli Ci się kolejny komunikat…',
          is_interactive: false
        },
        {
          id: 14,
          url: 'https://euromisja.pl/api/files/download/6050579e-15a4-4017-9371-58dd69f14582/ekran14.png',
          description: '<p>Na zadane pytanie odpowiedz…</p>' +
            '<p>TAK – aby wypuścić przecenę do akceptacji w systemie POS2 (KH, KM, FKM – rozszerzony)</p>' +
            '<p>NIE – aby zapisać koszyk ale nie zostanie on wysłany do akceptacji </p>' +
            '<b>Uwaga:</b> Jeśli koszyk nie zostanie wysłany do północy, automatycznie się wyczyści. ',
          is_interactive: false
        },
        {
          id: 15,
          url: 'https://euromisja.pl/api/files/download/c702b908-f433-4e05-9325-0494e5b8a4e3/ekran15.png',
          description: `W przypadku dodawania przecen na OiW nie podajemy terminu a jedynie samą ilość.`,
          is_interactive: false
        },
        {
          id: 16,
          url: 'https://euromisja.pl/api/files/download/0f0457ae-233a-46b1-bec1-963bcd10646f/ekran02b.png',
          description: '<p>Już prawie koniec! Jesteś coraz bliżej otrzymania nagrody.</p>' +
            'Przejdźmy jeszcze raz przez proces rejestracji terminów przydatności, którą znajdziesz pod ikoną Terminy przydatności.',
          is_interactive: false
        },
        {
          id: 17,
          url: 'https://euromisja.pl/api/files/download/1064f751-96c1-49e8-8dc2-fae1804f745c/ekran16a.png',
          description: `<p><b>Rejestr terminów ważności</b> – tu pracownik rejestruje/odznacza wszystkie produkty z terminami ` +
            'wybiegającymi w przyszłość, które obecnie nie nadają się do przeceny, ale są jego zdaniem zagrożone ' +
            'rotacją i mogą się terminować w przyszłości.</p>',
          is_interactive: false
        },
        {
          id: 18,
          url: 'https://euromisja.pl/api/files/download/03b941e3-d8dd-4979-9caa-5abf42943bee/ekran16b.png',
          description:
            '<p><b>Centralna weryfikacja terminów</b> – zadania z konkretnymi produktami do sprawdzenia terminów oraz ilości, ' +
            'nadawane przez : centralę, AM czy też KH danej hali </p>',
          is_interactive: false
        },
        {
          id: 19,
          url: 'https://euromisja.pl/api/files/download/c4778993-7952-4133-bdc9-97afd5da9bc4/ekran16c.png',
          description:
            '<b>Systemowa weryfikacja terminów</b> – tu wpadają zadania z 2 pozycji opisanych powyżej. Jeżeli nadal będą produkty na ' +
            'stanie hali od zgłoszenia i zarejestrowania, odkładają się jako zadania „operator”. „System” – to ' +
            'propozycje systemu (automatyczne wskazanie produktów do kontroli) – weryfikowane na podstawie daty wprowadzenia produktu na halę.',
          is_interactive: false
        },
        {
          id: 20,
          url: 'https://euromisja.pl/api/files/download/8db65966-28a5-44a0-b9e5-8d72bfad7b2d/ekran16.png',
          description: '<b>ZADANIE:</b> Jak przejść do kontroli terminów wybiegających w przyszłość ze swojego działu? ' +
            'Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 21,
          url: 'https://euromisja.pl/api/files/download/ccac3b77-3934-489c-9b85-4531be31df09/ekran17.png',
          description: 'Przy rejestracji terminów podanie daty ważności jest obowiązkowe.' +
            'Istnieje możliwość wydrukowania zarejestrowanych terminów przydatności po zakończeniu ich spisywania (w programie POS2).',
          is_interactive: false
        },
        {
          id: 22,
          url: 'https://euromisja.pl/api/files/download/e693ecb8-d2d1-45f4-9ffd-bf7a6233b5ff/ekran18.png',
          description: 'Kolejna ikona to Centralna weryfikacja terminów – tu operator ma pakiety zadań dziennych. Wchodząc ' +
            'w dany dzień weryfikuje terminy zgłoszone wcześniej w Rejestracji terminów przydatności lub ' +
            'Centralnej weryfikacji terminów.',
          is_interactive: false
        },
        {
          id: 23,
          url: 'https://euromisja.pl/api/files/download/73154880-4281-4dc0-940f-d9fc50f54daf/ekran19.png',
          description: '<p>Jak jakiś termin zbliża się do końca a system nie zaobserwował zejścia go ze stanu wrzuca go ' +
            'tutaj celem weryfikacji.' +
            'Tu operator może podać nowy stan z danym terminem i przesłać go dalej, po zamknięciu działania do ' +
            'akceptacji w POS2 przez KH, KM, FKM – rozszerzony. </p>' +
            '<b>ZADANIE:</b> Jak pracownik może odfiltrować swoje wcześniej zgłoszone produkty do przeceny? Aby przejść dalej, ' +
            'kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 24,
          url: 'https://euromisja.pl/api/files/download/fa129fae-f94f-45d4-a988-2a54d010a1fe/ekran01bez.png',
          description: '<p>Brawo! Udało Ci się zaliczyć zadanie makietowe.</p>' +
            '<p>Od zdobycia nagrody dzieli Cię tylko wykonanie krótkiego quizu.</p>' +
            '<b>Uwaga:</b> możesz podejść do niego tylko raz! Gotów? Zaczynajmy!',
          is_interactive: false,
          qa_button: true,
        }]
  },
  {
    task_id: 2,
    qa_id: 140,
    slides:
      [
        {
          id: 0,
          url: 'https://euromisja.pl/api/files/download/16d26dc2-14b9-477e-81c9-ee754b2d5fd0/zadanie_makietowe_slajd_startowy.png',
          description: 'Kliknij strzałkę, aby przejść do kolejnego slajdu.',
          is_interactive: false
        },
        {
          id: 1,
          url: 'https://euromisja.pl/api/files/download/5f685fae-c855-4a04-9eba-5ac843a9321c/2-zadanie-marketingowe-slajd_2.png',
          description: '<p><b>ZADANIE MAKIETOWE: Aplikacja mobilna</b></p>' +
            '<p>Zapoznaj się z instrukcjami na kolejnych planszach i postępuj zgodnie z poleceniami. ' +
            'Za prawidłowe przejście przez szkolenie i rozwiązanie końcowego quizu „Aplikacja mobilna” ' +
            'otrzymasz nagrodę - 4000 cegieł.</p>' +
            'Przeklikuj się przez slajdy za pomocą strzałek. W przypadku niektórych slajdów, aby przejść dalej, ' +
            'poprosimy Cię o kliknięcie w określony obszar na makiecie. Powodzenia!',
          is_interactive: false
        },
        {
          id: 2,
          url: 'https://euromisja.pl/api/files/download/62d55c86-ff27-418b-af9d-8d1e92e75b9e/2-zadanie-marketingowe-slajd_1.png',
          description:
            '<p>Użytkownik powinien zalogować się do aplikacji wpisując swoje dane dostępowe. ' +
            'Loginem zawsze jest NIP, hasło użytkownik ustala indywidualnie.</p>' +
            '<b>Ważne!</b> W celu pierwszego logowania klient powinien zwrócić się do Kierownika Hurtowni, ' +
            'który wygeneruje dla klienta hasło jednorazowe.',
          is_interactive: false
        },
        {
          id: 3,
          url: 'https://euromisja.pl/api/files/download/5f685fae-c855-4a04-9eba-5ac843a9321c/2-zadanie-marketingowe-slajd_2.png',
          description: 'Jak dodać produkt do koszyka?' + '<p>SPOSÓB 1: Wpisując nazwę szukanego produktu oraz zaznaczając ilość.</p>' +
            '<b>ZADANIE:</b> W którym miejscu możemy wyszukać dany produkt? Aby przejść do następnego slajdu, ' +
            'kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 4,
          url: 'https://euromisja.pl/api/files/download/186fc0c7-27f2-4d8f-a4ce-75c49f53d876/2-zadanie-marketingowe-slajd_3a.png',
          description: '<p>Znajdujesz się w oknie wyszukiwarki produktów.</p>' +
            '<p><b>Aby wyszukać produkt</b> należy kliknąć w puste pole obok „lupki” i wpisać nazwę szukanego produktu. ' +
            '<b>Aby dodać interesujący nas produkt</b> należy kliknąć „+”, a towar automatycznie zostanie dodany do koszyka. ' +
            '<b>Aby zmienić tryb dodawania szt/opakowań</b> należy jeden raz kliknąć w przycisk „szt”.</p>',
          is_interactive: false
        },
        {
          id: 5,
          url: 'https://euromisja.pl/api/files/download/622b7749-611e-48a4-9859-fb5eca577e82/2-zadanie-marketingowe-slajd_3.png',
          description:
            '<b>ZADANIE:</b> W którym miejscu zmienisz tryb dodawania produktów z „sztuki” na „opakowania”? ' +
            'Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie. ',
          is_interactive: true,
          has_more_area: true,
        },
        {
          id: 6,
          url: 'https://euromisja.pl/api/files/download/5f685fae-c855-4a04-9eba-5ac843a9321c/2-zadanie-marketingowe-slajd_2.png',
          description: '<p>Jak dodać produkt do koszyka? <br>' +
            'SPOSÓB 2: Skanując kod kreskowy produktu i zaznaczając ilość.<br></p>' +
            '<b>ZADANIE:</b> Gdzie możesz zeskanować produkt? Aby przejść do następnego slajdu, znajdź tryb skanera i ' +
            'kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 7,
          url: 'https://euromisja.pl/api/files/download/ec33c069-81b4-4f15-8cc1-6bbde82158c0/2-zadanie-marketingowe-slajd_7.png',
          description: '<p>Po wejściu w skaner możemy łatwo zeskanować kod kreskowy produktu.</p>',
          is_interactive: false
        },
        {
          id: 8,
          url: 'https://euromisja.pl/api/files/download/a44ff334-b522-4a06-86d4-70271f58e270/2-zadanie-marketingowe-slajd_7a.png',
          description: '<p>Możemy określić ilość zeskanowanego produktu, którą chcemy dodać do koszyka. ' +
            'Po zwiększeniu/zmniejszeniu ilości zeskanowanego towaru, produkty automatycznie dodadzą lub odejmą się z koszyka.</p>' +
            '<b>ZADANIE:</b> Gdzie musisz kliknąć, aby zwiększyć ilość danego produktu i dodać go do koszyka? ' +
            'Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 9,
          url: 'https://euromisja.pl/api/files/download/a44ff334-b522-4a06-86d4-70271f58e270/2-zadanie-marketingowe-slajd_7a.png',
          description:
            '<b>ZADANIE:</b> Gdzie musisz kliknąć, aby sprawdzić zawartość koszyka? Aby przejść do następnego slajdu, ' +
            'kliknij w odpowiednie miejsce na makiecie.',
          is_interactive: true
        },
        {
          id: 10,
          url: 'https://euromisja.pl/api/files/download/4fbada15-4f74-41f8-9334-446ed5863f58/2-zadanie-marketingowe-slajd_4a.png',
          description: 'Aby wysłać koszyk do fakturowania, należy wpierw kliknąć w przycisk <b>Sprawdź koszyk.</b>',
          is_interactive: false
        },
        {
          id: 11,
          url: 'https://euromisja.pl/api/files/download/2f6b0b86-4b22-470d-967d-eff6cbe4ccbb/2-zadanie-marketingowe-slajd_5a.png',
          description: 'Następnie należy zatwierdzić chęć wysłania koszyka klikając w zielony przycisk.',
          is_interactive: false
        },
        {
          id: 12,
          url: 'https://euromisja.pl/api/files/download/e09d6f25-d581-4120-bf3d-9f9e6092875e/2-zadanie-marketingowe-slajd_6.png',
          description: 'Po skutecznym wysłaniu koszyka pojawi się komunikat potwierdzający wysłanie koszyka.',
          is_interactive: false
        },
        {
          id: 13,
          url: 'https://euromisja.pl/api/files/download/5f685fae-c855-4a04-9eba-5ac843a9321c/2-zadanie-marketingowe-slajd_2.png',
          description: 'Brawo! Udało Ci się zaliczyć zadanie makietowe.<br>' +
            '<b>Od zdobycia nagrody dzieli Cię tylko wykonanie krótkiego quizu.</b><br> Uwaga, możesz podejść do niego tylko raz!' +
            ' Gotów? Zaczynajmy!',
          is_interactive: false,
          qa_button: true,
        }
      ]
  },
  {
    task_id: 3,
    qa_id: 233,
    slides:
      [{
        id: 0,
        url: 'https://euromisja.pl/api/files/download/16d26dc2-14b9-477e-81c9-ee754b2d5fd0/zadanie_makietowe_slajd_startowy.png',
        description: 'Kliknij strzałkę, aby przejść do kolejnego slajdu.',
        is_interactive: false
      },        {
        id: 1,
        url: 'https://euromisja.pl/api/files/download/e7318d9d-8efa-4255-bbde-0c57124fa9d0/sklep_1.png',
        description: '<p><b>ZADANIE MAKIETOWE: Marketplace</b></p>' +
          '<p>Zapoznaj się z instrukcjami na kolejnych planszach i postępuj zgodnie z poleceniami. Za prawidłowe ' +
          'przejście przez szkolenie i rozwiązanie końcowego quizu „Marketplace” otrzymasz nagrodę - 4000 cegieł.</p>' +
          'Przeklikuj się przez slajdy za pomocą strzałek. W przypadku niektórych slajdów, aby przejść dalej, ' +
          'poprosimy Cię o kliknięcie w określony obszar na makiecie. Powodzenia!',
        is_interactive: false
      },        {
        id: 2,
        url: 'https://euromisja.pl/api/files/download/e7318d9d-8efa-4255-bbde-0c57124fa9d0/sklep_1.png',
        description: 'Eurocash.pl Market to nowa platforma hurtowa z asortymentem uzupełniającym regularną ofertę.' +
          '<br>Klient EC Cash&Carry, EC Dystrybucja, EC Serwis na eurocash.pl Market ma już założone konto.',
        is_interactive: false
      },        {
        id: 3,
        url: 'https://euromisja.pl/api/files/download/afe41100-739c-4df6-a897-7b246e77b274/sklep_1b.png',
        description: 'Klikając <b>Logowanie dla Klienta</b> (lub Wejdź i zamów na dole strony) klient zostanie ' +
          'przekierowany do strony logowania. Przycisk <b>Logowanie dla Sprzedawcy</b> dotyczy producentów/dystrybutorów,' +
          ' którzy wystawiają produkty na sprzedaż.',
        is_interactive: false
      },        {
        id: 4,
        url: 'https://euromisja.pl/api/files/download/afe41100-739c-4df6-a897-7b246e77b274/sklep_1b.png',
        description:
          '<b>ZADANIE:</b> Gdzie Klient powinien kliknąć, aby zarejestrować się do platformy jako nowy Klient?<br>' +
          'Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie.',
        is_interactive: true
      },        {
        id: 5,
        url: 'https://euromisja.pl/api/files/download/28e12e23-7241-4235-8efa-e700f7f0b299/sklep_2.png',
        description: 'Po kliknięciu <i>Wejdź i zamów</i> oraz <i>Logowanie dla Klienta</i> widzimy stronę do logowania.<br>' +
          '<b>Klienci ECD oraz ECS</b> używają swoich danych do logowania takich samych jak na eurocash.pl. ' +
          '<b>Klienci ECC</b> mają dostępne hasła w swojej aplikacji ECC w skrzynce odbiorczej, loginem jest NIP.<br>' +
          'Natomiast <b>Nowy Klient lub ten, który nie posiada aplikacji ECC</b> powinien wypełnić formularz ' +
          'klikając <b>„Zarejestruj się”</b> w celu założenia konta.',
        is_interactive: false
      },        {
        id: 6,
        url: 'https://euromisja.pl/api/files/download/28e12e23-7241-4235-8efa-e700f7f0b299/sklep_2.png',
        description:
          '<b>ZADANIE:</b> W którym miejscu Klient powinien wpisać swój NIP?<br>' +
          'Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie.',
        is_interactive: true
      },        {
        id: 7,
        url: 'https://euromisja.pl/api/files/download/41aedbb0-117e-4175-9d27-044cc231fc5d/sklep_3b.png',
        description: 'Wchodząc w zakładkę <b><i>Zakupy</i></b> klient zobaczy wszystkie dostępne produkty. ' +
          '<b>Wyszukiwarka produktów</b> znajduje się zawsze na górze strony. Banery na stronie głównej ' +
          'pokazują nowości, bestsellery, okazje.',
        is_interactive: false
      },        {
        id: 8,
        url: 'https://euromisja.pl/api/files/download/41aedbb0-117e-4175-9d27-044cc231fc5d/sklep_3b.png',
        description:
          '<b>ZADANIE:</b> W którym miejscu znajdziesz wszystkie kategorie produktowe?<br>' +
          'Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie.',
        is_interactive: true
      },        {
        id: 9,
        url: 'https://euromisja.pl/api/files/download/9753fb71-9a33-4567-b488-2c14b409a38b/sklep_4b.png',
        description: 'W zakładce <b><i>Moje konto</i></b> znajdują się wszystkie informacje o koncie klienta: koszyki, które ' +
          'należy opłacić, numery zamówień, terminy oraz statusy dostaw, faktury zamówionych produktów, złożone ' +
          'przez klienta bądź pracownika BOK reklamacje, kod odbioru w przypadku zamówienia produktów ' +
          'z odbiorem w hurtowni.',
        is_interactive: false
      },        {
        id: 10,
        url: 'https://euromisja.pl/api/files/download/9753fb71-9a33-4567-b488-2c14b409a38b/sklep_4b.png',
        description:
          '<b>ZADANIE:</b> W której zakładce znajdziesz planowany termin dostawy zamówionych produktów?<br>' +
          'Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie.',
        is_interactive: true
      },        {
        id: 11,
        url: 'https://euromisja.pl/api/files/download/dcc65db9-cd69-4adc-876e-1fd1ab28783f/sklep_5b.png',
        description: 'Klient wchodząc w koszyk może znaleźć jaką logistyką otrzyma dostawę oraz ewentualne ' +
          'koszty dostawy, wartość zamówienia netto i brutto. Klikając w <b><i>Użyj kodu rabatowego</i></b> klient może ' +
          'zastosować rabat. Poprzez <b><i>Planowany termin dostawy</i></b> można ustalić dogodną dla klienta datę dostawy.',
        is_interactive: false
      },        {
        id: 12,
        url: 'https://euromisja.pl/api/files/download/dcc65db9-cd69-4adc-876e-1fd1ab28783f/sklep_5b.png',
        description:
          '<b>ZADANIE:</b> W którym miejscu znajdziesz informację o rodzaju dostawy?<br>' +
          'Aby przejść do następnego slajdu, kliknij w odpowiednie miejsce na makiecie.',
        is_interactive: true,
        has_more_area: true,
      },        {
        id: 13,
        url: 'https://euromisja.pl/api/files/download/e7318d9d-8efa-4255-bbde-0c57124fa9d0/sklep_1.png',
        description: '<b>Brawo! Udało Ci się zaliczyć zadanie makietowe.</b>' +
          '<p>Od zdobycia nagrody dzieli Cię tylko wykonanie krótkiego quizu. Uwaga, możesz podejść do niego tylko raz!</p>' +
          'Gotów? Zaczynajmy!',
        is_interactive: false,
        qa_button: true,
      }]
  },
];
