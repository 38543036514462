import { GAME_EVENTS } from "../../../constants";
import { getAdditionalBoardData } from "../../../game-gui/helpers/buildings.helper";
import { ADDITIONAL_BOARD_DATA } from "../../config/additional-board-data.config";
import { TileMenuButtonConfig } from "../../interfaces/tile-menu-config.interface";

export const CUSTOM_BUTTONS_CONFIG: { [buttonName: string]: TileMenuButtonConfig } = {
  warehouseSale: {
    x: 0,
    y: -135,
    name: "tile-menu.warehouse-sale",
    frame: "warehouse_1.png",
    hoverFrame: "warehouse_1_h.png",
    activeFrame: "warehouse_1_a.png",
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return this.building.hasOwnProperty(ADDITIONAL_BOARD_DATA.OPEN_WAREHOUSE_SALE);
    },
  },
  storage: {
    x: 100,
    y: 90,
    name: "tile-menu.storage",
    frame: "warehouse.png",
    hoverFrame: "warehouse_h.png",
    activeFrame: "warehouse_a.png",
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return getAdditionalBoardData(ADDITIONAL_BOARD_DATA.OPEN_STORAGE_ID, this.building);
    },
  },
  badges: {
    x: 0,
    y: -135,
    name: "tile-menu.badges",
    frame: "badges.png",
    hoverFrame: "badges_h.png",
    activeFrame: "badges_a.png",
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return this.building.group === "statue";
    },
  },
};
