import { Component, Input, OnInit } from "@angular/core";
import { Moment } from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { EVENTS } from "../../../../../../../../core/consts/core/events";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../../core/providers/assets.service";
import { CurrencyService } from "../../../../../../../../core/providers/currency.service";
import { GlobalService } from "../../../../../../../../core/providers/global.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { CARD_TEMPLATE } from "../../../../consts/custom/card-template.const";
import {
  CURRENCY_ID_TO_DISPLAY,
  NUMBER_OF_CURRENCIES_TO_DISPLAY_IN_CARD_TASK,
} from "../../../../consts/custom/currency-id-to-display.const";
import { FALLBACK_CURRENCY } from "../../../../consts/custom/fallback-currency.const";
import { CardTask } from "../../../../interfaces/custom/card-task.interface";
import { CardTaskDetailsComponent } from "../../dialogs/card-task-details/card-task-details.component";

@Component({
  selector: "card-task",
  templateUrl: "./card-task.component.html",
})
export class CardTaskComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(CurrencyService) currencyService: CurrencyService;

  @Input() cardTask: CardTask;
  @Input() cardTasks: CardTask[];
  @Input() momentDate: Moment;

  STOCK_VIEW = STOCK_VIEW;
  FALLBACK_CURRENCY = FALLBACK_CURRENCY; // ??
  CARD_TEMPLATE = CARD_TEMPLATE;

  actionCard() {
    this.openCardTask();
  }

  openCardTask() {
    this.dialogService.open(
      CardTaskDetailsComponent,
      {
        data: {
          cardTask: this.cardTask,
          cardTasks: this.cardTasks,
          momentDate: this.momentDate,
        },
      },
      (data?) => {
        if (data && data.reload) {
          this.globalService.globalEvents.emit({ name: EVENTS.GUI.RELOAD_CARDS });
        }
      }
    );
  }

  protected readonly CURRENCY_ID_TO_DISPLAY = CURRENCY_ID_TO_DISPLAY;
  protected readonly NUMBER_OF_CURRENCIES_TO_DISPLAY_IN_CARD_TASK = NUMBER_OF_CURRENCIES_TO_DISPLAY_IN_CARD_TASK;
}
