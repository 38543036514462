import { Directive, Injectable, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { interval } from "rxjs";
import { map, startWith } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { ApiService } from "../../../../../../core/providers/api.service";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { SynchronizeTimeService } from "../../../../../../core/providers/synchronize-time.service";
import { Player } from "../../../../../player/interfaces/player";
import { DialogService } from "../../../../../shared/providers/dialog.service";

@Directive()
@Injectable()
export abstract class AbstractHudOffsetTimeComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(SynchronizeTimeService) st: SynchronizeTimeService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(DialogService) dialogService: DialogService;
  @Input() player: Player;
  timer$;
  isActive = false;
  isActiveLogger = false;
  isActiveShortKeys = false;
  currentTimeToNextClick: number;
  window = window;
  keyLocalStorage = "offsetTimeShortkeys";

  get errorAssetsLength() {
    return window["errorAssets"] ? window["errorAssets"].size : 0;
  }

  buttons = [
    { label: "+24h", time: 86400, timeout: 10 },
    { label: "+16h", time: 57600, timeout: 8 },
    { label: "+12h", time: 43200, timeout: 5 },
    { label: "+6h", time: 21600, timeout: 3 },
    { label: "+2h", time: 7200, timeout: 3 },
    { label: "+60m", time: 3600, timeout: 2 },
    { label: "+30m", time: 1800, timeout: 2 },
    { label: "+5m", time: 300, timeout: 1 },
    { label: "+2m", time: 120, timeout: 1} ,
    { label: "+1m", time: 60, timeout: 1 },
  ];

  shortkeys = [];

  ngOnInit() {
    this.initShortKeys();
  }

  listenerShortKeys() {
    if (this.globalService.isDevDomain) {
      document.addEventListener("keydown", event => {
        this.shortkeys.forEach(shortkey => {
          if (event.shiftKey && event.altKey && event.code === shortkey.code && shortkey.button) {
            this.testSpeedUp(shortkey.button);
          }
        });
      });
    }
  }

  initShortKeys() {
    this.loadShortKeysFromLS();
    this.listenerShortKeys();
  }

  loadShortKeysFromLS() {
    const offsetTimeShortkeys = localStorage.getItem(this.keyLocalStorage);

    if (offsetTimeShortkeys) {
      this.shortkeys = JSON.parse(offsetTimeShortkeys);
    } else {
      this.shortkeys = [
        {
          label: "SHIFT + ALT + ",
          code: "Digit1",
          button: null,
        },
        {
          label: "SHIFT + ALT + ",
          code: "Digit2",
          button: null,
        },
      ];
    }
  }

  selectButton(time, shortkey) {
    shortkey.button = this.buttons.find(button => button.time === Number(time));
    localStorage.setItem(this.keyLocalStorage, JSON.stringify(this.shortkeys));
  }

  startTimer() {
    const diff = moment(this.player.real_time).diff(this.st.getActualLocalTime());
    this.timer$ = interval(1000).pipe(
      startWith(0),
      map(() => {
        return moment(this.st.getActualLocalTime()).add(diff, "milliseconds");
      })
    );
  }

  testSpeedUp(button) {
    this.currentTimeToNextClick = button.timeout;

    this.testSpeedUpRequest(button.time);
  }

  testSpeedUpRequest(seconds) {
    this.apiService
      .post("testing/speed-up", {
        body: {
          seconds,
        },
      })
      .subscribe(
        () => {
          this.setIntervalToLockButton();
        },
        () => {
          this.setIntervalToLockButton();
        }
      );
  }

  setIntervalToLockButton() {
    setTimeout(() => {
      if (this.currentTimeToNextClick > 0) {
        this.currentTimeToNextClick--;
        this.setIntervalToLockButton();
      } else {
        this.currentTimeToNextClick = 0;
      }
    }, 1000);
  }

  toggle() {
    this.isActive = !this.isActive;

    if (!this.isActive) {
      this.isActiveShortKeys = false;
    }
  }

  toggleLogger() {
    this.isActiveLogger = !this.isActiveLogger;
  }

  toggleShortkeys() {
    this.isActiveShortKeys = !this.isActiveShortKeys;
  }

  logErrorAssets() {
    const arrayErrorAssets = window["errorAssets"] && Array.from(window["errorAssets"]);
    console.ow(arrayErrorAssets);
    // console.log(arrayErrorAssets);
  }
}
