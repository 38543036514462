import { Component } from '@angular/core';
import { AbstractChooseTaskComponent } from '../../../../abstract/custom/abstract-choose-task.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';

@Component({
  selector: 'app-choose-task',
  templateUrl: './choose-task.component.html',
})
export class ChooseTaskComponent extends AbstractChooseTaskComponent {
  @OwInject(DialogService) dialogService: DialogService;
}
