import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiService } from "../../../../../../../../core/providers/api.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiBusinessQuestionService } from "../../../../api/custom/services/api-business-question.service";
import { Question, QuestionAnswer } from "../../../../interfaces/custom/question.interface";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
})
export class QuestionComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessQuestionService) apiBusinessQuestionService: ApiBusinessQuestionService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<QuestionComponent>;
  @OwInject(MAT_DIALOG_DATA) data: {
    question: Question;
    fromList: boolean;
  };
  TEMPLATES = {
    QUESTION_DETAILS: "question-details",
    QUESTION_AND_ANSWERS: "question-and-answers",
  };
  activeTemplate: string;

  STOCK_VIEW = STOCK_VIEW;
  question: Question;
  isReloadAfterBackToList: boolean;

  ngOnInit() {
    this.question = this.data.question;
    this.changeTemplate(this.TEMPLATES.QUESTION_DETAILS);
  }

  changeTemplate(template: string) {
    this.activeTemplate = template;
  }

  actionAnswer(question, answer: QuestionAnswer) {
    if (this.question.answered_at) {
      return;
    }

    question.selected_answer = answer;
  }

  endQuestion(question: Question) {
    const chosenAnswer = question.answers.find(answer => answer.answer_id === question.selected_answer.answer_id);
    chosenAnswer.is_selected_answer = true;

    this.apiBusinessQuestionService
      .postQuestion({
        playerQuestionId: this.question.player_question_id,
        answers: this.getSelectedAnswers(),
      })
      .subscribe(resp => {
        this.question = resp;
        this.changeTemplate(this.TEMPLATES.QUESTION_DETAILS);
        this.isReloadAfterBackToList = true;
      });
  }

  getSelectedAnswers() {
    return this.question.answers.filter(answer => answer.is_selected_answer).map(answer => answer.answer_id);
  }

  openExplanation() {
    this.dialogService.openAlert({
      description: this.question.explanation,
    });
  }

  backToList() {
    this.matDialogRef.close(this.isReloadAfterBackToList);
  }
}
