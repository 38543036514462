<ng-container *ngIf="playerService.player as player">
  <ng-container *ngIf="visible">
    <div class="options">
      <div class="left"></div>

      <div class="right">
        <!-- DAILY QUESTIONS -->
        <button
          (click)="openDailyQuestions()"
          *ngIf="player.gui_unlocks['daily_questions']"
          matTooltip="Pytania codzienne"
          class="square small primary margin"
          matTooltipPosition="above"
          matTooltipShowDelay="300"
        >
          <img [src]="assetsService.assetsData['/ui/questions_hud.png'].path">
        </button>

        <!-- QA -->
        <button
          (click)="openQaList()"
          *ngIf="player.gui_unlocks['qa']"
          matTooltip="Quizy"
          class="square small primary margin"
          matTooltipPosition="above"
          matTooltipShowDelay="300"
        >
          <img [src]="assetsService.assetsData['/ui/quiz_hud.png'].path">
        </button>

        <!-- RANKS -->
        <button
          (click)="openRankList()"
          *ngIf="player.gui_unlocks['ranks']"
          matTooltip="Rankingi Biznesowe"
          class="square small primary margin"
          matTooltipPosition="above"
          matTooltipShowDelay="300"
        >
          <img [src]="assetsService.assetsData['/ui/ranking_2_hud.png'].path">
        </button>

        <button
          (click)="openRankListGames()"
          *ngIf="player.gui_unlocks['ranks']"
          matTooltip="Rankingi ze Świata Gry"
          class="square small primary margin"
          matTooltipPosition="above"
          matTooltipShowDelay="300"
        >
          <img [src]="assetsService.assetsData['/ui/rankings_hud.png'].path">
        </button>

        <!-- NAVIGATION -->
        <ng-container *ngIf="paginationDate">
          <!-- PREV -->
          <button
            (click)="prev()"
            [disabled]="!paginationDate.allowPrev"
            class="square small primary prev"
          >
            <i class="fas fa-chevron-left"></i>
          </button>

          <!-- CURRENT -->
          <span class="date">
          <loading
            [classNames]="['white', 'small']"
            [customLogicShow]="customLoading"
          ></loading>
          <div *ngIf="!customLoading">
            {{ paginationDate.current.startOf('month').format('MMMM') }}&nbsp;&nbsp;|&nbsp;&nbsp;
            {{ paginationDate.current.year() }}
          </div>
        </span>

          <!-- NEXT -->
          <button
            (click)="next()"
            [disabled]="!paginationDate.allowNext"
            class="square small primary next"
          >
            <i class="fas fa-chevron-right"></i>
          </button>
        </ng-container>

        <button
          (click)="toggleVisibleCards()"
          [class.is-hide]="hideCards"
          class="square small primary hide"
        >
          <i [ngClass]="hideCards ? 'fa-arrow-alt-to-top' : 'fa-arrow-alt-to-bottom'" class="fas"></i>
        </button>
      </div>
    </div>

    <div [class.hide]="hideCards" class="cards">
      <ng-container *ngFor="let cardTask of allCardTasks">
        <card-task
          [cardTask]="cardTask"
          [cardTasks]="allCardTasks"
          [momentDate]="momentDate"
        ></card-task>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
