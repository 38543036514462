import { Injectable } from '@angular/core';
import { ProductAttribute } from '../../interfaces/custom/product-attribute.interface';

@Injectable({
  providedIn: 'root'
})
export class PlayerAttributesService {
  playerProductAttributes: ProductAttribute[];

  getAttributeByName(name: string, productAttributes: ProductAttribute[] = this.playerProductAttributes) {
    return productAttributes.find(attribute => attribute.name === name);
  }
}
