import { NgClass, NgSwitch, NgTemplateOutlet } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

// import * as R from "ramda";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { SharedModule } from "../../../../../../../shared/shared.module";
// import { CHEST_STATUS } from "../../../../../../constants";
// import { Chest } from "../../../../../chests/abstract/custom/abstract-chests.component";
// import { ApiChestsService } from "../../../../../chests/api/custom/services/api-chests.service";
// import { EVENT_DIALOGS_NAMES_CHESTS_CUSTOM } from "../../../../../chests/consts/event-dialogs/event-names.const";
// eslint-disable-next-line max-len
// import { EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM } from "../../../../../museum-collection/consts/custom/event-dialogs/event-names.const";
// import { EVENT_DIALOGS_NAMES_WAREHOUSE } from "../../../../../warehouse/consts/core/event-dialogs/event-names.const";
import { AbstractHudParametersComponent } from "../../../../abstract/core/abstract-hud-parameters.component";
import { ApiPlayerAttributesService } from "../../../../api/custom/services/api-player-attributes.service";
// import { ChangeMapService } from "../../../../services/custom/change-map.service";
import { PlayerAttributesService } from "../../../../services/custom/player-attributes.service";
import { ParameterDefinition } from "../../../../../../interfaces/parameters";
import { PlayerParameterBalanceHud } from "../../../../interfaces/core/player-parameter-balance-hud.interface";

@Component({
  selector: "app-hud-parameters",
  templateUrl: "./hud-parameters.component.html",
  // styleUrls: ["./hud-parameters.component.scss"],
})
export class HudParameters2Component extends AbstractHudParametersComponent implements OnInit {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  // @OwInject(ApiChestsService) apiChestsService: ApiChestsService;
  @OwInject(ApiPlayerAttributesService) apiPlayerAttributesService: ApiPlayerAttributesService;
  @OwInject(PlayerAttributesService) playerAttributesService: PlayerAttributesService;
  // @OwInject(ChangeMapService) changeMapService: ChangeMapService;
  // countChest: number;
  languageKnowledge: number;
  loadTimeout = null;
  // GROUP_ONE = [1, 2, 3, 4, 5, 6, 7, 8];
  // GROUP_TWO = [];

  ngOnInit() {
    this.subscribeGlobalEvents();
    this.subscribePlayer();
    // console.log("parameterDefinitions:",this.parameterDefinitions);
    // console.log("parameterBalances:",this.parameterBalances);
  }

  // subscribeGlobalEvents() {
  //   this.subs.global = this.changeMapService.subscribeGlobalEvents(() => {
  //     if (this.changeMapService.isTreasuresScene) {
  //       if (this.player) {
  //         this.getOtherBalances();
  //       }
  //     }
  //     this.setHudParameters();
  //   });
  // }

  // openChests() {
  //   this.eventEmitterDialogsService.emitter.emit({
  //     name: EVENT_DIALOGS_NAMES_CHESTS_CUSTOM.CHESTS,
  //   });
  // }

  // openStorage() {
  //   this.eventEmitterDialogsService.emitter.emit({
  //     name: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE,
  //     config: {
  //       data: {
  //         storageId: 3,
  //       },
  //     },
  //   });
  // }

  // getPlayerChests() {
  //   this.apiChestsService.playerChests().subscribe((chests: Chest[]) => {
  //     this.countChest = chests.filter(chest => chest.status === CHEST_STATUS.CLOSED).length;
  //   });
  // }

  getPlayerProductAttributes() {
    this.apiPlayerAttributesService.getPlayerProductAttributes().subscribe(() => {
      const attribute = this.playerAttributesService.getAttributeByName("LANGUAGE_KNOWLEDGE");

      if (attribute && attribute.max_value_per_product > 0) {
        this.languageKnowledge = Math.floor((attribute.value * 100) / attribute.max_value_per_product);
      }
    });
  }

  afterPlayerSubscribe() {
    super.afterPlayerSubscribe();

    if (this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }

    this.loadTimeout = setTimeout(() => {
      this.getOtherBalances();
    }, 2000);
  }

  getOtherBalances() {
    // if (this.changeMapService.isTreasuresScene) {
    // this.getPlayerChests();
    // this.getPlayerProductAttributes();
    // }
  }

  // openMuseum() {
  //   this.eventEmitterDialogsService.emitter.emit({
  //     name: EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM.MUSEUM_COLLECTION_LIST,
  //   });
  // }

  setHudParameters() {
    super.setHudParameters();
    // this.filterGroupParameter();
  }

  // filterGroupParameter() {
  //   const group = this.changeMapService.isTreasuresScene ? this.GROUP_TWO : this.GROUP_ONE;
  //   this.parameterBalances = this.parameterBalances.filter(parameter => R.contains(parameter.parameter_id, group));
  // }
}
