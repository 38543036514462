import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractWarehouseComponent } from "../../../../abstract/core/abstract-warehouse.component";

@Component({
  selector: "app-custom-warehouse",
  templateUrl: "./warehouse.component.html",
})
export class CustomWarehouseComponent extends AbstractWarehouseComponent implements OnInit, OnDestroy, AfterViewInit {
  ngOnInit() {
    this.getStorages();
    this.subscribePlayer();
  }

  ngAfterViewInit() {
    if (!this.swiper) {
      this.setSwiper();
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
