export const CUSTOM_TILE_MAP_WIDTH = null;
export const CUSTOM_TILE_MAP_HEIGHT = null;
export const CUSTOM_TILE_WIDTH = null;
export const CUSTOM_TILE_HEIGHT = null;
export const CUSTOM_TILE_TOOLTIP_OFFSET = {
  '1x1': {
    x: 0,
    y: -100
  },
  '2x1': {
    x: 0,
    y: -250
  },
  '2x2': {
    x: 0,
    y: -250
  },
  '3x2': {
    x: 0,
    y: -250
  },
  '1x3': {
    x: 250,
    y: -450
  },
  '3x1': {
    x: -250,
    y: -450
  },
};
export const CUSTOM_TILE_HOVER_TYPES = {};
export const CUSTOM_TILE_HOVER_TYPE_ANCHORS = {};
export const CUSTOM_TILE_TYPE_POLYGON = {};
export const CUSTOM_TILE_MENU_POSITION_OFFSETS = {};
export const CUSTOM_BUILDINGS_LEVEL_COLOR = {};
