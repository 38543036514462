import { Component, OnInit } from "@angular/core";
import { AbstractMessageDetailsBaseComponent } from "../../../../abstract/core/abstract-message-details-base.component";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { MessageDetailsMissionSuccess } from "../../../../interfaces/custom/message-details-mission-success.interface";

@Component({
  selector: "app-message-details-mission-success",
  templateUrl: "./message-details-mission-success.component.html",
})
export class MessageDetailsMissionSuccessComponent extends AbstractMessageDetailsBaseComponent implements OnInit {
  contribution;
  message: MessageDetailsMissionSuccess;
  STOCK_VIEW = STOCK_VIEW;

  PLACEHOLDERS = [
    {
      placeholder: "%contribution",
    },
  ];

  ngOnInit() {
    this.afterInit();
    this.setTitleDesc();
  }

  setTitleDesc() {
    if (this.message) {
      this.contribution = this.message.parameters?.currencies?.find(c => c.currency_id == 2);

      Object.keys(this.PLACEHOLDERS).forEach((key) => {
        const placeholderObject: { placeholder: string, getValue: () => any } = this.PLACEHOLDERS[key];

        if (placeholderObject.placeholder === "%contribution") {
          placeholderObject.getValue = () => `${this.contribution?.amount}`;
          this.message.description = this.message.description.replace(placeholderObject.placeholder, placeholderObject.getValue());
        }
      });
    }
  }
}
