<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>
  <!-- BACK BAR -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="condition && productOrCurrency">
    <div class="container loading-opacity">
      <div class="product-in-glow-box">
        <!-- ITEM ICON -->
        <div
          *ngIf="productOrCurrency.iconUrlBig"
          class="product-icon"
          [style.background-image]="'url(' + productOrCurrency.iconUrlBig + ')'"></div>

        <!-- ITEM BALANCE -->
        <span class="product-balance">
          {{ productOrCurrency.balance | NumberWithSpaces }}
        </span>
        <div class="clip-path"></div>
      </div>

      <!-- ITEM NAME -->
      <p class="product-name">
        {{ productOrCurrency.name }}
      </p>

      <div class="form-container">
        <!-- FORM -->
        <form [formGroup]="form">
          <input #inputProductAmount (input)="checkInput()" type="number" min="0" max="999999999" formControlName="amount" [placeholder]="'missions.form.set-amount' | myTranslate" />
        </form>

        <!-- SELL ALL -->
        <button class="only-border no-hover-blur" (click)="setMax()" [disabled]="productOrCurrency.balance === 0">
          {{ "missions.form.sell-all" | myTranslate }}
        </button>
      </div>

      <!-- SUMMARY -->
      <p class="summary">
        {{ "missions.left-to-hand-over" | myTranslate : [this.requiredAmount] }}
      </p>
    </div>

    <!-- BUTTONS -->
    <div class="action-buttons">
      <!-- ERROR -->
      <div class="error" *ngIf="form.value.amount > productOrCurrency.balance">
        {{ "missions.enough-material" | myTranslate }}
      </div>
      <!-- BUTTON HAND OVER -->
      <div class="bottom-bar">
        <div class="bar-button base">
          <button
            class="base primary"
            [disabled]="form.invalid || form.value.amount > productOrCurrency.balance"
            (click)="missionHandOver()">
            {{ "missions.transfer-button-hand-over" | myTranslate }}<br />
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
