<!-- DIALOG EXIT -->
<m-ui-close-button></m-ui-close-button>

<!-- PLAYER BAR -->
<app-hud-player
  [allowHover]="false"
  [changeProfileImage]="true"
  [player]="player"
></app-hud-player>

<!-- EXPERIENCE -->
<span class="experience">
  {{ player.points_balance | NumberWithSpaces }} {{ 'player-details.exp' | myTranslate }}
</span>

<div class="name">
  {{ playerService.player.first_name }}
  {{ playerService.player.last_name }}
</div>

<div class="separator"></div>

<div class="email">
  {{ playerService.player.email }}
</div>

<div class="buttons">
  <!-- GUIDE -->
  <a
    [href]="LINKS.MANUAL"
    target="_blank"
  >
    <button class="base primary">
      Pobierz przewodnik
    </button>
  </a>

  <!-- TERMS -->
  <a
    [href]="LINKS.TERMS"
    target="_blank"
  >
    <button class="base primary">
      {{ 'player-details.terms' | myTranslate }}
    </button>
  </a>

  <!-- LOGOUT -->
  <span id="logout" (click)="logout()">
    {{ 'player-details.logout' | myTranslate }}
  </span>
</div>
