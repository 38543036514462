import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { A2hsService } from "../../../../services/custom/a2hs.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
})
export class LandingComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(A2hsService) a2hsService: A2hsService;

  timer = 1;
  intervalTimer = null;
  isLoading = true;
  expirationDate = "January 30 2024 12:00:00 GMT+0100";

  ngOnInit() {
    setTimeout(()=>{
      this.a2hsService.checkPWAisAvailable();
    });
    this.setTimer();
  }

  login() {
    if (this.timer <= 0) {
      this.router.navigate(["/login"]);
    }
  }
  setTimer() {
    this.intervalTimer = setInterval(() => {
      const actualDate = new Date().getTime();
      const expiredAt = new Date(this.expirationDate).getTime();
      this.timer = moment(expiredAt).diff(moment(actualDate), "seconds");
      this.isLoading = false;
      if (this.timer <= 0) {
        clearInterval(this.intervalTimer);
      }
    }, 1000);
  }

  convertTime(seconds) {
    seconds = Number(seconds);

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const hour = h < 10 ? "0" + h + " : " : h + " : ";
    const min = m < 10 ? "0" + m + " : " : m + " : ";
    const sec = s < 10 ? "0" + s : s;

    return hour + min + sec;
  }

  addApplicationToHomeScreen() {
    this.a2hsService.addApplicationToHomeScreen();
  }

  ngOnDestroy() {
    clearInterval(this.intervalTimer);
  }
}
