import { Injectable } from "@angular/core";

import { RankingsEventDialogsService } from "../../../modules/game/game-ui/rankings/services/custom/event-dialogs.service";

@Injectable({
  providedIn: "root",
})
export class RegisterCustomEventDialogsService {
  constructor(private rankingsEventDialogsService: RankingsEventDialogsService) {}
}
