<div class="player-resources" *ngIf="player && visible">
  <ng-container *ngFor="let parameterBalance of parameterBalances">
    <div
      class="bar"
      [ngbTooltip]="parameterBalance.tooltip && tooltipTpl"
      container="body"
      tooltipClass="delayed"
      placement="bottom-left"
      #tooltip="ngbTooltip"
      (mousedown)="tooltip.open()"
      [ngClass]="parameterBalance.key"
    >
      <div class="icon-container">
        <img class="ow-icon" [src]="parameterBalance.key + '.png' | asset:'parameters/hud'">
      </div>

      <div class="resource-container">
        <ng-container [ngSwitch]="parameterBalance.type">
          <!-- TYPE 1 -->
          <ng-container
            *ngSwitchCase="'population_delta'"
            [ngTemplateOutlet]="parameterType1"
            [ngTemplateOutletContext]="{parameter: parameterBalance}"
          ></ng-container>

          <!-- TYPE 1 -->
          <ng-container
                  *ngSwitchCase="'resources'"
                  [ngTemplateOutlet]="parameterResources"
                  [ngTemplateOutletContext]="{parameter: parameterBalance}"
          ></ng-container>

          <!-- TYPE OTHER -->
          <ng-container
            *ngSwitchDefault
            [ngTemplateOutlet]="parameterTypeOther"
            [ngTemplateOutletContext]="{parameter: parameterBalance}"
          ></ng-container>
        </ng-container>
      </div>
    </div>

    <!-- TOOLTIP -->
    <ng-template #tooltipTpl>
      <div
        class="tooltip-contianer"
        [innerHTML]="parameterBalance.tooltip"
      ></div>
    </ng-template>
  </ng-container>

<!--  <ng-container *ngIf="changeMapService.isTreasuresScene">-->
    <!-- CHESTS -->
<!--    <div-->
<!--      class="bar pointer"-->
<!--      [ngbTooltip]="'Twoje skrzynie'"-->
<!--      container="body"-->
<!--      tooltipClass="delayed"-->
<!--      placement="bottom-left"-->
<!--      #tooltip="ngbTooltip"-->
<!--      (mousedown)="tooltip.open()"-->
<!--      (click)="openChests()"-->
<!--    >-->
<!--      <div class="icon-container">-->
<!--        <div class="ow-icon chests"></div>-->
<!--      </div>-->

<!--      <div class="resource-container">-->
<!--        {{ countChest !== undefined ? countChest : '-'}}-->
<!--      </div>-->
<!--    </div>-->

    <!-- MUSEUM -->
<!--    <div-->
<!--      *ngIf="player.gui_unlocks['museum'] !== false"-->
<!--      class="bar pointer"-->
<!--      [ngbTooltip]="'Zwiększaj wartość ekspozycji muzealnej, odnajdując historyczne artefakty i tworząc ich kolekcje w muzeum.'"-->
<!--      container="body"-->
<!--      tooltipClass="delayed"-->
<!--      placement="bottom-left"-->
<!--      #tooltip="ngbTooltip"-->
<!--      (mousedown)="tooltip.open()"-->
<!--      (click)="openMuseum()"-->
<!--    >-->
<!--      <div class="icon-container">-->
<!--        <div class="ow-icon museum"></div>-->
<!--      </div>-->

<!--      <div class="resource-container">-->
<!--        {{ player.museum_worth }}-->
<!--      </div>-->
<!--    </div>-->

    <!-- LANGUAGE -->
<!--    <div-->
<!--      *ngIf="player.gui_unlocks['language_knowledge'] !== false"-->
<!--      class="bar"-->
<!--      [ngbTooltip]="'Znajomość języka tubylców'"-->
<!--      container="body"-->
<!--      tooltipClass="delayed"-->
<!--      placement="bottom-left"-->
<!--      #tooltip="ngbTooltip"-->
<!--      (mousedown)="tooltip.open()"-->
<!--    >-->
<!--      <div class="icon-container">-->
<!--        <div class="ow-icon language"></div>-->
<!--      </div>-->

<!--      <div class="resource-container">-->
<!--        {{ languageKnowledge !== undefined ? (languageKnowledge + '%') : '-' }}-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->
</div>

<!-- TYPE 1 -->
<ng-template #parameterType1 let-parameter="parameter">
  {{ parameter.displayHud | NumberWithSpaces }}

  <div
    class="delta"
    *ngIf="player.population_delta as delta"
    [ngClass]="delta > 0 ? 'green' : 'red'"
  >
      <span>
        {{ delta > 0 ? '+' : '' }}<!--
        -->{{ delta }}
      </span>
    <i class="fas" [ngClass]="delta > 0 ? 'fa-arrow-alt-up' : 'fa-arrow-alt-down'"></i>
  </div>
</ng-template>

<!-- RESOURCES -->
<ng-template #parameterResources let-parameter="parameter">
  <p>{{ parameter.parameter_id === 7 ? (parameter.income | NumberWithSpaces) : parameter.displayHud + '%'}}</p>

</ng-template>

<!-- TYPE 4 -->
<ng-template #parameterTypeOther let-parameter="parameter">
  {{ parameter.displayHud }}%
</ng-template>
