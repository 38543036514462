import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replace",
})
export class ReplacePipe implements PipeTransform {
  transform(input: string, args: { [key: string]: any } = {}): string {
    if (typeof input !== "string") {
      return input;
    }

    for (const key in args) {
      input = input.replace(key, args[key]);
    }

    return input;
  }
}
