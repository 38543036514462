import * as HammerJS from "hammerjs";
import { Scene } from "phaser";

import { BOARD_TILE_HEIGHT, GAME_EVENTS, MapTypeKeyMap, PHASER_CAMERA_ZOOM } from "../../../constants";
import { GameFeaturesMap } from "../../../constants/game-features";
import { GameService } from "../../../services/game.service";
import { SceneConfig } from "../../interfaces/scene-config.interface";
import { CenterMap } from "../../interfaces/shared";
import { calculateMinZoomValue } from "../../utils/game.helper";
import { Board } from "../custom/Board.class";
import { IsoMap } from "./IsoMap";
import { IsoMapLayer } from "./IsoMapLayer";
import { MyGame } from "./MyGame";

const ZOOM_MAX = 1;

export class MyScene extends Scene {
  passedData: any;

  tiledMapKey: string;

  pinchStartScale = 1;
  gameService: GameService;
  cameraCanMove = true;
  isoMap: IsoMap;
  board: Board;
  buildingsLayer: IsoMapLayer;

  roadsLayer: IsoMapLayer;

  pointerStart = {
    x: 0,
    y: 0,
  };

  pressed: boolean;

  car;
  pointerDownPosition = {
    x: 0,
    y: 0,
  };

  cameraStartPos = {
    x: 0,
    y: 0,
  };

  timeoutId;

  phaserEvents: Phaser.Events.EventEmitter;
  topLayer: IsoMapLayer;
  onMouseWheel = handleMouseWheel.bind(this);

  sceneConfig?: SceneConfig;
  hammer: HammerManager;

  constructor(config) {
    super(config);
    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener("onorientationchange", this.resize.bind(this));
  }

  setSceneConfig(config: SceneConfig) {
    this.sceneConfig = config;
  }

  setCurrentScene() {
    (this.game as MyGame).currentScene = this;
  }

  resize() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      const w = window.innerWidth * window.devicePixelRatio;
      const h = window.innerHeight * window.devicePixelRatio;
      console.ow("resize", "w", window.innerWidth, "h", window.innerHeight, "pix", window.devicePixelRatio);
      this.scale.resize(w, h);
      this.cameras.resize(w, h);
      if (this.cameras.main) {
        this.cameras.main.zoomTo(Math.max(this.cameras.main.zoom, calculateMinZoomValue(this.cameras.main)), 0);
      }
    }, 250);
  }

  setCameraControls() {
    if (!GameFeaturesMap.cameraControls) {
      return;
    }
    this.input.on("pointerdown", (pointer, gameObject) => {
      // if (pointer.event.altKey) {
      //   console.log(pointer.worldX, pointer.worldY, pointer);
      // }
      this.pointerStart = pointer.position.clone();
      this.pointerDownPosition = pointer.position.clone();
    });

    this.events.on(
      "lockCamera",
      () => {
        this.cameraCanMove = false;
      },
      this
    );
    this.events.on(
      "unlockCamera",
      () => {
        this.cameraCanMove = true;
      },
      this
    );

    // if (this.gameService.deviceDetector.isMobile()) {
    //   addEventListener('click', (event) => {
    //     if (window.innerHeight !== screen.height) {
    //       this.scale.stopFullscreen();
    //       this.scale.startFullscreen();
    //     }
    //   });
    // }

    this.input.on("pointermove", (pointer, ge) => {
      const zoom = this.cameras.main.zoom;

      if (this.car) {
        this.car.x = pointer.worldX;
        this.car.y = pointer.worldY;
        this.car.calculateDepth();
      }

      if (pointer.isDown && this.cameraCanMove) {
        this.cameras.main.scrollX += (this.pointerStart.x - pointer.position.x) / zoom;
        this.cameras.main.scrollY += (this.pointerStart.y - pointer.position.y) / zoom;
        this.pointerStart = pointer.position.clone();
      }
    });

    this.hammer = new HammerJS(document.querySelector("body"));
    this.hammer.get("pinch").set({ enable: true });
    this.hammer.on("pinchstart", event => {
      this.pinchStartScale = this.cameras.main.zoom;
    });

    this.hammer.on("press", event => {
      this.pressed = true;
      setTimeout(() => (this.pressed = false), 3000);
    });

    this.hammer.on("tap", (event: any) => {
      if (this.pressed && event.tapCount === 5) {
        this.gameService.setDebugMode();
      }
    });

    this.hammer.on("pinch", event => {
      const bounds = this.cameras.main.getBounds();
      const scaleW = this.cameras.main.width / bounds.width;
      const scaleH = this.cameras.main.height / bounds.height;
      const minZoomValue = Math.max(scaleH, scaleW);

      const zoomValue = Phaser.Math.Clamp(this.pinchStartScale * event.scale, minZoomValue, ZOOM_MAX);

      this.cameras.main.zoom = zoomValue;
      this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom);
    });

    addEventListener("wheel", this.onMouseWheel);

    addEventListener("keydown", event => {
      if (event.altKey && event.ctrlKey && event.code === "KeyR") {
        this.gameService.setDebugMode();
      }
    });

    (this.game as MyGame).currentScene = this;
    // console.log((this.game as MyGame).currentScene);
  }

  destroyScene() {
    this.hammer && this.hammer.destroy();
    removeEventListener("wheel", this.onMouseWheel);
    this.buildingsLayer = null; // @todo: check why is's need to be cleared
    this.board = null;
  }

  public shouldCancelClickEvent(pointerPosition: Phaser.Math.Vector2) {
    return (
      Phaser.Math.Distance.Between(this.pointerDownPosition.x, this.pointerDownPosition.y, pointerPosition.x, pointerPosition.y) >
      MOUSE_DISTANCE_TO_CANCEL_CLICK
    );
  }

  focusOn(centerOn: CenterMap) {
    const targetTile = this.board.boardTiles.find(tile => tile.tileData.tile_id === centerOn.tile_id);

    if (!targetTile && this.gameService.lastPlayerIslandId !== centerOn.player_island_id) {
      this.gameService.centerOn = centerOn;
      this.gameService.prepareGameData(this.gameService.playerService.getActivePlayerId(), centerOn.player_island_id);
      this.gameService.globalService.globalEvents.emit({
        name: GAME_EVENTS.START_SCENE,
        value: MapTypeKeyMap[centerOn.type],
      });
      return;
    }

    this.add.tween({
      targets: this.cameras.main,
      duration: 600,
      ease: "Power2",
      scrollX: targetTile.x - this.cameras.main.width / 2,
      scrollY: targetTile.y - this.cameras.main.height / 2 - BOARD_TILE_HEIGHT / 2,
      zoom: 1,
      onUpdate: () => this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom),
    });
    this.gameService.centerOn = null;
  }
}

function handleMouseWheel(event: WheelEvent) {
  if (!this.input.isOver || (this.input.isOver && this.gameService.dialogService.dialog.openDialogs.length > 0)) {
    return;
  }

  const deltaY = event.deltaY ? -event.deltaY : event.detail;

  const zoomDelta = 0.1;
  let zoomTarget;
  if (deltaY < 0) {
    zoomTarget = this.cameras.main.zoom - zoomDelta;
  } else {
    zoomTarget = this.cameras.main.zoom + zoomDelta;
  }

  const cameraCallback = () => {
    if (this.phaserEvents) {
      this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom);
    }
  };

  const minZoomValue = calculateMinZoomValue(this.cameras.main);

  this.cameras.main.zoomTo(Phaser.Math.Clamp(zoomTarget, minZoomValue, ZOOM_MAX), 300, "Expo.easeOut", true, cameraCallback.bind(this));
}

const MOUSE_DISTANCE_TO_CANCEL_CLICK = 10;
