import { Injectable } from "@angular/core";
import { map, tap } from "rxjs/operators";

import { ApiService } from "../../../core/providers/api.service";
import { PlayerService } from "../../player/providers/player.service";
import { MOCK_BOARD_KEY } from "../mock/consts/mock-board.const";

@Injectable({
  providedIn: "root",
})
export class BoardService {
  constructor(private apiService: ApiService, private playerService: PlayerService) {}

  getWorld(playerId: number) {
    return this.apiService.get(`player/${playerId}/region`).pipe(
      tap(regions => {
        this.playerService.playerRegions.next({
          playerId,
          regions,
        });
      })
    );
  }

  getBoard(playerId: number, islandId?: number) {
    return this.apiService.get(
      `player/${playerId}/board`,
      {
        params: {
          player_island_id: islandId,
        },
      },
      MOCK_BOARD_KEY
    ).pipe(
      map(resp => {
        resp.forEach((tile) => {
          if (tile.branch_building) {
            const building = tile.branch_building;
            building.player_mission = building.branch_mission;
            building.player_building_id = building.branch_building_id;
            tile.player_building = building;
            tile.player_tile_id = -1 * tile.tile_id;
          }
        });

        return resp;
      })
    );
  }

  buyTile(tileId: number) {
    const options = {
      body: {
        tile_id: tileId,
      },
    };
    return this.apiService.post("board/tile", options);
  }
}
