import { Injectable } from "@angular/core";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { Observable } from "rxjs";
import { GetMissionBranchPlayersRequest } from "../interfaces/requests/get-mission-branch-players-request.interface";
import { ApiOptions } from "../../../../../../../core/interfaces/api-options";

@Injectable({
  providedIn: "root",
})
export class ApiMissionBranchService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  getMissionBranch({ branch_mission_id }: { branch_mission_id: number }): Observable<any> {
    return this.apiService.get(`branch-missions/${branch_mission_id}`);
  }

  getMissionBranchPlayers({ branch_mission_id }: {
    branch_mission_id: number
  }): Observable<GetMissionBranchPlayersRequest[]> {
    return this.apiService.get(`branch-missions/${branch_mission_id}/players`);
  }

  patchMissionBranchHandOver({ amount, branch_mission_id }: {
    amount: number,
    branch_mission_id: number
  }): Observable<any> {
    const options: ApiOptions = {
      body: {
        amount,
      },
    };

    return this.apiService.patch(`branch-missions/${branch_mission_id}/hand-over`, options);
  }
}
