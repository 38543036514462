<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TOP BAR BASE -->
  <div *ngIf="rank?.name" class="top-bar base">
    <!-- RANK NAME -->
    <div class="title">
      {{ rank.name }}
    </div>
  </div>

  <!-- RANK DETAILS -->
  <div class="rank-details-container loading-opacity" *ngIf="rankResults">
    <ng-container *ngIf="rankResults?.results?.length; else noItemsTpl">
      <!-- RANK RESULTS -->
      <!--        <p class="rank-condition-header">Czy spelniono min</p>-->
      <ow-perfect-scrollbar  [class.without-my-position]="!rankResults?.my_position">
        <table>
          <thead>
            <th></th>
            <th></th>
            <th *ngIf="rank?.value1_label">{{ rank?.value1_label }}</th>
            <th *ngIf="rank?.value2_label">{{ rank?.value2_label }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr *ngFor="let row of rankResults?.results; let i = index">
              <td class="rank-position">{{ row.position }}</td>
              <td class="rank-name">{{ row.first_name + " " + row.last_name }}</td>
              <td *ngIf="rank?.value1_label" class="rank-value1">
                {{ row.value1 }}
              </td>
              <td *ngIf="rank?.value2_label" class="rank-value2">
                {{ row.value2 }}
              </td>
              <td class="rank-value2">
                <ng-container *ngFor="let currency of row.currency_prizes">
                  <m-ui-currency [item]="currency" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
                </ng-container>
                <ng-container *ngIf="!(row?.currency_prizes?.length > 0)">
                  {{ " " }}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
      <div class="black-line"></div>
      <div class="my-position-label" *ngIf="rankResults?.my_position">Twoja pozycja w rankingu</div>
      <table class="my-position-data" *ngIf="rankResults?.my_position">
        <tbody>
          <tr>
            <td class="rank-position">{{ rankResults.my_position.position }}</td>
            <td class="rank-name">{{ rankResults.my_position.first_name + " " + rankResults.my_position.last_name }}</td>
            <td *ngIf="rank?.value1_label" class="rank-value1">
              {{ rankResults.my_position.value1 }}
            </td>
            <td *ngIf="rank?.value2_label" class="rank-value2">
              {{ rankResults.my_position.value2 }}
            </td>
            <td class="rank-value2">
              <ng-container *ngFor="let currency of rankResults?.my_position.currency_prizes">
                <m-ui-currency [item]="currency" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>
              </ng-container>
              <ng-container *ngIf="!(rankResults?.my_position?.currency_prizes?.length > 0)">
                {{ " " }}
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <!--        <div class="my-position-data" *ngIf="rankResults.my_position">-->
      <!--          <div class="position-number-and-name">-->
      <!--            <p class="position-number">{{rankResults.my_position.position}}</p>-->
      <!--            <p> {{rankResults.my_position.first_name + " " + rankResults.my_position.last_name}}</p>-->
      <!--          </div>-->
      <!--          <div class="position-value">-->
      <!--            <p *ngIf="rank?.value1_label" class="rank-value1" >-->
      <!--              {{rankResults.my_position.value1}}-->
      <!--            </p>-->
      <!--            <p *ngIf="rank?.value2_label" class="rank-value2">-->
      <!--              {{rankResults.my_position.value2}}-->
      <!--            </p>-->
      <!--            <p class="currency">-->
      <!--              <m-ui-currency *ngIf="rankResults.my_position?.currency_prizes[0]" [item]="rankResults.my_position?.currency_prizes[0]" [stockView]="STOCK_VIEW.J" [lack]="false"></m-ui-currency>-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </div>-->
    </ng-container>
    <ng-template #noItemsTpl>
      <div class="flex-center no-items">Aktualnie brak graczy spełniających założenia tego rankingu.</div>
    </ng-template>
  </div>
</div>
