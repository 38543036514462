<!--GAME ENGINE -->
<app-mobile-game-engine-pce></app-mobile-game-engine-pce>

<!-- GAME UI -->
<template #appHudTpl></template>
<template #appHudTpl2></template>

<app-device-rotate></app-device-rotate>

<game-loaded-placeholder></game-loaded-placeholder>
