import { Component, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiBusinessRewardCodesService, RewardCode } from "../../../../api/custom/services/api-business-reward-codes.service";

@Component({
  selector: "app-reward-codes",
  templateUrl: "./reward-codes.component.html",
  styleUrls: ["./reward-codes.component.scss"],
})
export class RewardCodesComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiBusinessRewardCodesService) apiBusinessRewardCodesService: ApiBusinessRewardCodesService;
  @OwInject(FormBuilder) formBuilder: FormBuilder;

  formGroup: FormGroup;
  isLoading: boolean = false;

  STOCK_VIEW = STOCK_VIEW;

  rewardsHistory: RewardCode[];
  customLoading: boolean;

  ngOnInit() {
    this.getRewardsHistory();
    this.formGroup = this.formBuilder.group({
      code: ["", [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
    });
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.customLoading = true;
    const code = this.formGroup.get("code").value;

    this.apiBusinessRewardCodesService.activateRewardCode(code).subscribe(
      resp => {
        this.customLoading = false;
        this.formGroup.reset();
        this.getRewardsHistory();
      },
      () => {
        this.customLoading = false;
      }
    );
  }

  getRewardsHistory() {
    this.customLoading = true;

    this.apiBusinessRewardCodesService.getRewardsHistory().subscribe(
      resp => {
        this.rewardsHistory = resp;
        this.customLoading = false;
      },
      () => {
        this.customLoading = false;
      }
    );
  }
}
