import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../shared/shared.module";
import { BuildingsModule } from "./buildings/module/core/buildings.module";
import { ChestModule } from "./chest/module/core/chest.module";
import { CurrencyExchangeModule } from "./currency-exchange/module/core/currency-exchange.module";
import { HudModule } from "./hud/module/core/hud.module";
import { MessageModule } from "./message/module/core/message.module";
import { MissionModule } from "./mission/module/core/mission.module";
import { QaMobileModule } from "./qa/module/core/qa-mobile.module";
import { RankingsModule } from "./rankings/module/custom/rankings.module";
import { TransactionHistoryModule } from "./transaction-history/module/core/transaction-history.module";
import { VirtualKeyboardMobileModule } from "./virtual-keyboard/mobile/virtual-keyboard-mobile.module";
import { WarehouseModule } from "./warehouse/module/core/warehouse.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    BuildingsModule,
    WarehouseModule,
    MissionModule,
    VirtualKeyboardMobileModule,
    ChestModule,
    MessageModule,
    QaMobileModule,
    CurrencyExchangeModule,
    TransactionHistoryModule,
    HudModule,
    RankingsModule,
  ],
})
export class GameUiMobileModule {}
