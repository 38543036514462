<div class="debug-container">
  <div class="debug-item" [class.expanded]="expandMap.game">
    <div (click)="expandMap.game = !expandMap.game" class="debug-item-header">Ustawienia sceny (domyślne)</div>
    <div class="debug-item-content">
      <label
        >Nazwa pliku mapy Tiled (puste pole wyłącza mapę):
        <input class="short" [(ngModel)]="sceneConfig.default.tiledMapFileName" />
        <button (click)="restartScene()">Restart</button>
      </label>

      <label
        >Rzut prostokątny?: {{ sceneConfig.default.isOrhto | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="sceneConfig.default.isOrhto" />
        <button (click)="restartScene()">Restart</button>
      </label>
    </div>
  </div>

  <div class="debug-item" [class.expanded]="expandMap.tileMenu">
    <div (click)="expandMap.tileMenu = !expandMap.tileMenu" class="debug-item-header">
      Ustawienia TileMenu/Tooltip/Hover (nie wymagają restartu)
    </div>
    <div class="debug-item-content" *ngIf="game && game.currentScene && game.currentScene.sceneConfig">
      <label
        >MENU: Maksymalny rozmiar przy oddaleniu:
        <input class="short" [(ngModel)]="game.currentScene.sceneConfig.tileMenuConfig.maxZoomScaleValue"
      /></label>
      <label
        >TOOLTIP: Maksymalny rozmiar przy oddaleniu:
        <input class="short" [(ngModel)]="game.currentScene.sceneConfig.tileTooltipConfig.maxZoomScaleValue"
      /></label>
      <label
        >HOVER: Maksymalny rozmiar przy oddaleniu:
        <input class="short" [(ngModel)]="game.currentScene.sceneConfig.tileHoverConfig.maxZoomScaleValue"
      /></label>
    </div>
  </div>

  <div class="debug-item" [class.expanded]="expandMap.worldInfo">
    <div (click)="expandMap.worldInfo = !expandMap.worldInfo" class="debug-item-header">Regiony i wyspy</div>
    <div class="debug-item-content">
      <app-world-info></app-world-info>
    </div>
  </div>

  <div class="debug-item" [class.expanded]="expandMap.isoToScreen">
    <div (click)="showScreenToIsoConverter()" class="debug-item-header">Screen to iso</div>
    <div class="debug-item-content">
      <app-to-iso></app-to-iso>
    </div>
  </div>

  <div class="debug-item" [class.expanded]="expandMap.tiles">
    <div (click)="expandMap.tiles = !expandMap.tiles" class="debug-item-header">Kafle</div>
    <div class="debug-item-content">
      <button class="color-primary mat-raised-button" (click)="showInputArea()">Pokaż/ukryj pole klikalne</button>
      <button class="ml" class="color-primary mat-raised-button" (click)="showTileInfo()">Pokaż/ukryj informacje o kaflach</button>
    </div>
  </div>

  <div class="debug-item" [class.expanded]="expandMap.features">
    <div (click)="expandMap.features = !expandMap.features" class="debug-item-header">Funkcjonalności</div>
    <div class="debug-item-content">
      <label
        >Board włączony: {{ gameFeaturesMap.board | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.board" />
        <button (click)="restartScene()">Restart</button>
      </label>

      <label
        >Graficzne tło sceny: {{ gameFeaturesMap.sceneBgImages | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.sceneBgImages" />
        <button (click)="restartScene()">Restart</button>
      </label>

      <label
        >Mapa kafli z Tiled: {{ gameFeaturesMap.tiledMap | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.tiledMap" />
        <button (click)="restartScene()">Restart</button>
      </label>

      <label
        >Interaktywność kafli: {{ gameFeaturesMap.boardTileInput | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.boardTileInput" />
        <button (click)="restartScene()">Restart</button>
      </label>

      <label
        >Tooltipy: {{ gameFeaturesMap.tooltip | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.tooltip" />
        <button (click)="restartScene()">Restart</button>
      </label>

      <label
        >Sterowanie kamerą: {{ gameFeaturesMap.cameraControls | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.cameraControls" />
        <button (click)="restartScene()">Restart</button>
      </label>

      <label
        >Wymuś CANVAS renderer: {{ gameFeaturesMap.forceCanvas | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.forceCanvas" />
        <button [routerLink]="['main/auth']">Reload</button>
      </label>

      <label
        >Wymuś 30fps (zamiast 60): {{ gameFeaturesMap.forceLowerFramerate | onOff }}
        <input type="checkbox" class="short" [(ngModel)]="gameFeaturesMap.forceLowerFramerate" />
        <button [routerLink]="['main/auth']">Reload</button>
      </label>
    </div>
  </div>

  <div class="debug-item" [class.expanded]="expandMap.other">
    <div (click)="expandMap.other = !expandMap.other" class="debug-item-header">Firebase</div>
    <div class="debug-item-content">
      <div class="item">
        Odpalona metoda registerFirebase():
        <button class="fire-btn">
          {{ registerCustomFirebaseTokenService.isRegisterFirebaseFired || 'false' }}
        </button>
      </div>

      <div class="item">
        Czy przeszło do pierwszego if'a w registerFirebase():
        <button class="fire-btn">
          {{ registerCustomFirebaseTokenService.didItGoToFirstIf || 'false' }}
        </button>
      </div>

      <div class="item">
        Czy pobrało token:
        <button class="fire-btn">
          {{ registerCustomFirebaseTokenService.downloadedToken || 'false' }}
        </button>
      </div>

      <div class="item">
        Catch error w registerFirebase():
        <button class="fire-btn">
          {{ registerCustomFirebaseTokenService.catchErrorMsg || 'brak' }}
        </button>
      </div>

      <div class="item">
        Request Permission:
        <button class="notification-req" (click)="requestPermission()">Click!</button>
      </div>
      <div class="item">
        Wyślij wiadomość typu push natychmiast
        <button class="color-primary" (click)="sendTestPushMessage(true)">Wyślij natychmiast!</button>
      </div>
      <div class="item">
        Wyślij wiadomość typu push po 15 sekundach
        <button class="color-primary" (click)="sendTestPushMessage(false)">Wyślij po 15s!</button>
      </div>
    </div>
  </div>

  <div class="debug-item" [class.expanded]="expandMap.other">
    <div (click)="expandMap.other = !expandMap.other" class="debug-item-header">Inne (z datGUI)</div>
    <div class="debug-item-content">
      <div class="other"></div>
    </div>
  </div>

  <div class="debug-item" [class.expanded]="expandMap.gameInfo">
    <div (click)="expandMap.gameInfo = !expandMap.gameInfo" class="debug-item-header">
      Informacje o grze
    </div>
    <div class="debug-item-content">
      <div class="info">
        <p>Rozdzielczość ekranu</p>
        <p>{{generalInformation.screenWidth}}x{{generalInformation.screenHeight}}</p>
      </div>
      <div class="info">
        <p>Rozdzielczość obszaru roboczego</p>
        <p>{{generalInformation.offsetWidth}}x{{generalInformation.offsetHeight}}</p>
      </div>
      <div class="info">
        <p>Tryb renderowania Phasera</p>
        <p>{{generalInformation.renderType?.mode}} ({{generalInformation.renderType?.text}})</p>
      </div>
      <div class="info">
        <p>Device Pixel Ratio</p>
        <p>{{generalInformation.pixelRatio}}</p>
      </div>
      <div class="info">
        <p>DPI</p>
        <p>{{generalInformation.dpi}} ({{(generalInformation.dpi / 0.96).toFixed(2)}}%)</p>
      </div>
      <div class="info">
        <p>PlayerID</p>
        <p>{{generalInformation.playerId}}</p>
      </div>
      <div class="info">
        <p>UserID</p>
        <p>{{generalInformation.userId}}</p>
      </div>
      <div class="info">
        <p>ExternalID</p>
        <p>{{generalInformation.externalId}}</p>
      </div>
      <div class="info">
        <p>BranchId</p>
        <p>{{generalInformation.branchId}}</p>
      </div>
      <div class="info">
        <p></p>
        <button (click)="gatherInformation()">Odswież powyższe dane</button>
      </div>
    </div>
  </div>
  <div class="debug-item" [class.expanded]="expandMap.currency">
    <div (click)="expandMap.currency = !expandMap.currency" class="debug-item-header">
      Naliczanie produktów i walut
    </div>
    <div class="debug-item-content">

      <div class="action-element">
        <label>Waluta</label>
        <div>
          <input list="currencyList" name="currencyListInp"
                 autocomplete="off"
                 [(ngModel)]="transaction.currencyName"
                 [disabled]="transaction.isProcessing"  />
          <datalist id="currencyList">
            <option *ngFor="let el of currencyList" [ngValue]="el.currency_id">{{el.text}}</option>
          </datalist>
          <input [disabled]="transaction.isProcessing"
                 type="number"
                 placeholder="ilość"
                 [(ngModel)]="transaction.currencyAmount"/>
          <button [disabled]="transaction.isProcessing"
                  (click)="triggerNewTransaction('currency')">Nalicz</button>
        </div>
      </div>
    </div>
    <div class="debug-item-content">
      <div class="action-element">
        <label>Produkty</label>
        <div>
          <input list="productsList" name="productsListInp"
                 autocomplete="off"
                 [(ngModel)]="transaction.productName"
                 [disabled]="transaction.isProcessing">
          <datalist id="productsList">
            <option *ngFor="let el of productList" [ngValue]="el.product_id">{{el.text}}</option>
          </datalist>
          <input [disabled]="transaction.isProcessing"
                 type="number"
                 placeholder="ilość"
                 [(ngModel)]="transaction.productAmount"/>
          <button [disabled]="transaction.isProcessing"
                  (click)="triggerNewTransaction('product')">Nalicz</button>
        </div>
      </div>
    </div>
    <div class="debug-item-content" *ngIf="transaction.resultText">
      <div class="action-element">
        <label [ngClass]="{
                    'green': transaction.addedSuccesfully,
                    'red': !transaction.addedSuccesfully
                }">{{transaction.resultText}}</label>
      </div>
    </div>
  </div>

</div>

<button class="button-toggle" (click)="toggle()">
  <i class="fas fa-arrow-alt-{{ isOpen ? 'right' : 'left' }}"></i>
</button>
