import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { QaAbstractModule } from "../../abstract/core/qa-abstract.module";
import { MQaSummaryComponent } from "../../base/core/components/qa-summary/qa-summary.component";
import { MQaDetailsComponent } from "../../base/core/dialogs/qa-details/qa-details.component";
import { MQaFinishComponent } from "../../base/core/dialogs/qa-finish/qa-finish.component";
import { MQaListComponent } from "../../base/core/dialogs/qa-list/qa-list.component";
import { CUSTOM_DECLARATIONS } from "../../consts/custom/components.const";
import { CUSTOM_IMPORTS } from "../../consts/custom/imports.const";
import { CUSTOM_PROVIDERS } from "../../consts/custom/providers.const";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

@NgModule({
  declarations: [MQaListComponent, MQaDetailsComponent, MQaSummaryComponent, MQaFinishComponent, ...CUSTOM_DECLARATIONS],
  imports: [CommonModule, QaAbstractModule, SharedUiMobileModule, SharedModule, FormsModule, ...CUSTOM_IMPORTS, OwPerfectScrollbarModule],
  providers: [...CUSTOM_PROVIDERS],
})
export class QaMobileModule {}
