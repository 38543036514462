const moduleName = "rankings";

export const EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM = {
  RANKING_LIST: `${moduleName}-list`,
  RANKING_DETAILS_GAME: `${moduleName}-details-game`,
  RANKING_DETAILS_WORLD: `${moduleName}-details-world`,
  RANKING_DETAILS_WORLD_FILTER: `${moduleName}-details-world-filter`,
  RANKING_INFORMATIONS_DASHBOARD: `${moduleName}-informations-dashboard`,

};
