<div class="background-image">
  <div class="wrapper">
    <loading></loading>

    <!-- LOGO -->
    <div class="stock-logo"></div>
    <div class="login-description">
      Kliknij zaloguj się przez azure,<br />
      aby automatycznie zalogować się do gry
    </div>
    <button class="base primary login-button" (click)="login()">Logowanie AZURE</button>
    <div class="error-text" *ngIf="error">{{ error }}</div>
    <div class="text-one">Jesteś tu pierwszy raz?</div>
    <button class="base primary landing-button" (click)="landing()">Poznaj swoją misję w grze</button>
    <button class="base primary pwa-button" *ngIf="a2hsService.showAddToHomeScreenButton" (click)="addApplicationToHomeScreen()">Dodaj aplikację do ekranu</button>
    <div class="email-contact">
      <a href="mailto:info@stocktown.pl">info@stocktown.pl</a>
    </div>
  </div>
</div>
