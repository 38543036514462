import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { GAME_CONFIG } from "../config/custom/_parsed-game.config";
import { OrientationManager } from "../helpers/orientation-manager.helper";
import { AndroidService } from "./android.service";

const MobileDetect = require("mobile-detect");

export const mobileDetect = new MobileDetect(window.navigator.userAgent);
export let mobileGui = false;
enum GUI {
  mobile,
  tablet,
  desktop,
}

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  gui: GUI;
  isMobile: boolean;
  resizeTimer: number;
  windowScale = 1;
  windowMinHeightPx = 534;
  externalBarPx = 60;
  dialogScale = 1;
  isAllowOrientation: boolean;
  allowOrientation = GAME_CONFIG.DEVICE_ALLOW_ORIENTATION;

  constructor(
    private router: Router,
    private androidService: AndroidService
  ) {
    this.checkAndSetMobileDevice();
    this.checkAndSetGui();
  }

  checkAndSetMobileDevice() {
    this.isMobile = mobileDetect.mobile() || this.checkSafariOnIpad();
  }

  checkAndSetGui() {
    this.gui = GUI.desktop;

    if (this.isMobile) {
      this.gui = GUI.mobile;

      const size = 1000;
      const someSizeMoreThan = window.innerHeight >= size || window.innerWidth >= size;
      const everySizeMoreThan = window.innerHeight >= size && window.innerWidth >= size;

      if (someSizeMoreThan) {
        this.gui = GUI.tablet;
      }

      if (everySizeMoreThan) {
        this.gui = GUI.desktop;
      }
    }

    this.handlerChange();
    this.startOrientationManager();
  }

  checkSafariOnIpad() {
    return mobileDetect["ua"].toLowerCase().includes("safari") && window.orientation !== undefined;
  }

  setBodyClass() {
    document.body.classList.remove("mobile");
    document.body.classList.remove("tablet");
    document.body.classList.remove("desktop");

    switch (this.gui) {
      case GUI.mobile:
        document.body.classList.add("mobile");
        break;

      case GUI.tablet:
        document.body.classList.add("tablet");
        break;

      case GUI.desktop:
        document.body.classList.add("desktop");
        break;
    }
  }

  disableMobileGui() {
    this.gui = GUI.desktop;
    this.refreshRouter();
  }

  activeMobileGui() {
    this.gui = GUI.mobile;
    this.refreshRouter();
  }

  refreshRouter() {
    this.handlerChange();
    this.router.navigateByUrl(this.router.url);
  }

  handlerChange() {
    this.setGlobalGui();
    this.setBodyClass();
    this.setOrientation();
  }

  setOrientation() {
    if (this.isMobile) {
      if (this.allowOrientation === "landscape") {
        this.isAllowOrientation = window.orientation === -90 || window.orientation === 90;
      }

      if (this.allowOrientation === "portrait") {
        this.isAllowOrientation = window.orientation === 0 || window.orientation === 180;
      }
    }

    if (this.androidService.nativeInterface) {
      this.isAllowOrientation = true;
    }
  }

  setGlobalGui() {
    mobileGui = this.gui !== GUI.desktop;
  }

  calcScale() {
    const tempScale = window.innerHeight / (this.windowMinHeightPx + this.externalBarPx);
    this.windowScale = tempScale >= 1 ? 1 : tempScale;
  }

  calcDialogScale() {
    this.dialogScale = 1;
  }

  startOrientationManager() {
    screen.orientation.addEventListener("change", () => {
      setTimeout(() => {
        if (OrientationManager.orientationChanged()) {
          this.handlerChange();
          location.reload();
        }
      }, 0);
    });
  }
}
