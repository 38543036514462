import { Component } from '@angular/core';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { PlayerService } from '../../../../../../../player/providers/player.service';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { AuthService } from '../../../../../../../auth/providers/auth.service';
import { GuiService } from '../../../../../../services/gui.service';
import { AppState } from '../../../../../../../../store/state';
import { select, Store } from '@ngrx/store';
import { LoginActions } from '../../../../../../../../store/auth/login';
import { LINKS } from '../../../../../../../../core/consts/core/links.const';
import { PlayerSelectors } from '../../../../../../../../store/player';

@Component({
  selector: 'hud-player-profile-details',
  templateUrl: './hud-player-profile-details.component.html',
})
export class HudPlayerProfileDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(Store) store: Store<AppState>;

  LINKS = LINKS;
  player;
  subs = {
    player: null,
  };

  ngOnInit(): void {
    this.subscribePlayer();
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer)
      )
      .subscribe((player) => {
        this.player = player;
      });
  }

  close() {
    this.dialogService.closeAll();
  }

  logout() {
    this.authService.logoutApi()
      .subscribe(() => {
        this.dialogService.closeAll();
        this.guiService.isSplashShow.next(true);
        this.store.dispatch(new LoginActions.AuthLogout());
      });
  }
}
