<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>
  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (click)="close()"></m-ui-close-button>

  <div class="container">
    <div class="banknotes">
      <p>
        {{"Liczba banknotów zdobytych za edukację: &nbsp;"}} <strong> {{ moneyInfo.edu_earnings }}</strong>{{'&nbsp;'}}
        <m-ui-currency
          [item]="{ currency_id: 1, amount: moneyInfo.edu_earnings }"
          [hideValue]="true"
          [stockView]="STOCK_VIEW.J"
          [lack]="false"></m-ui-currency>
      </p>
      <p>
        {{"Liczba banknotów zdobytych za zadania biznesowe: &nbsp;"}}<strong>{{ moneyInfo.business_earnings}}</strong>{{'&nbsp;'}}
        <m-ui-currency
          [item]="{ currency_id: 1, amount: moneyInfo.business_earnings }"
          [hideValue]="true"
          [stockView]="STOCK_VIEW.J"
          [lack]="false"></m-ui-currency>
      </p>
      <p>
        {{"Liczba niewykorzystanych banknotów: &nbsp;"}}<strong>{{ moneyInfo.balance }}</strong>{{'&nbsp;'}}
        <m-ui-currency
          [item]="{ currency_id: 1, amount: moneyInfo.balance }"
          [hideValue]="true"
          [stockView]="STOCK_VIEW.J"
          [lack]="false"></m-ui-currency>
      </p>
    </div>
    <div class="description">
      Wydając banknoty zdobywasz punkty doświadczenia w grze. Podejmując dobre decyzje w grze możesz zdobyć do 10% dodatkowych punktów
      doświadczenia.
    </div>
  </div>
</div>
