import { SceneConfigMap } from '../interfaces/scene-config.interface';
import { BASIC_SCENE_BASE_CONFIG } from './basic-scene.base-config';
import * as R from 'ramda';
import { BRANCH_SCENE_CONFIG_KEY } from "../../constants";
import { CITY_SCENE_CONFIG_KEY } from "../consts/core/game-map.const";

export const BASIC_SCENE_CUSTOM_CONFIG: SceneConfigMap = {
    default: {
        ...R.clone(BASIC_SCENE_BASE_CONFIG),
        tiledStartOffset: {
            x: 0,
            y: 0
        },
        tiledJsonPrefix: null,
        backgroundImages: [
            {
                filename: 'map-0-0.jpg',
                x: 0,
                y: 0,
                z: -1,
                key: 'map-0-0',
                displayWidth: 4096,
                displayHeight: 4096,
            },
            {
                filename: 'map-1-0.jpg',
                x: 4096,
                y: 0,
                z: -1,
                key: 'map-1-0',
                displayWidth: 4096,
                displayHeight: 4096,
            },
            {
                filename: 'map-2-0.jpg',
                x: 8192,
                y: 0,
                z: -1,
                key: 'map-2-0',
                displayWidth: 1808,
                displayHeight: 4096,
            },
            {
                filename: 'map-0-1.jpg',
                x: 0,
                y: 4096,
                z: -1,
                key: 'map-0-1',
                displayWidth: 4096,
                displayHeight: 2904,
            },
            {
                filename: 'map-1-1.jpg',
                x: 4096,
                y: 4096,
                z: -1,
                key: 'map-1-1',
                displayWidth: 4096,
                displayHeight: 2904,
            },
            {
                filename: 'map-2-1.jpg',
                x: 8192,
                y: 4096,
                z: -1,
                key: 'map-2-1',
                displayWidth: 1808,
                displayHeight: 2904,
            },
        ],
        mapDirectoryName: 'map',
        tiledMapFileName: null,
        islandGroupIdForBoard: 'A',
        boardStartOffset: {
            x: 5000,
            y: 3648
        },
        tileMenuAtlasDirectory: 'tile-menu',
        cameraBounds: {
            x: 0,
            y: 0,
            width: 10000,
            height: 7000
        },
        other: {
            sceneZoom: {
                max: 0.45,
                min: 0.70
            },
            sceneSize: {
                width: 6650,
                height: 4500
            },
            sceneKeyName: CITY_SCENE_CONFIG_KEY,
            cameraPosition: 'center',
            zoomMode: 'half',
        }
    },
    [BRANCH_SCENE_CONFIG_KEY]: {
        ...R.clone(BASIC_SCENE_BASE_CONFIG),
        isOrhto: true,
        boardStartOffset: {
            x: 0,
            y: 0
        },
        yAxisSign: 1,
        tiledStartOffset: {
            x: 0,
            y: 0
        },
        tiledJsonPrefix: null,
        backgroundImages: [
            {
                filename: 'branch.png',
                x: 0,
                y: 0,
                z: -1,
                key: 'branch',
                displayWidth: 3000,
                displayHeight: 1400,
            }
        ],
        mapDirectoryName: 'branch',
        tiledMapFileName: null,
        islandGroupIdForBoard: 'E',
        cameraStartPosition: {
            x: 0,
            y: 0
        },
        tileMenuAtlasDirectory: 'tile-menu',
        cameraBounds: {
            x: 0,
            y: 0,
            width: 3000,
            height: 1400
        },
        other: {
            sceneZoom: {
                max: 0.5,
                min: 0.9
            },
            sceneSize: {
                width: 3000,
                height: 1400
            },
            sceneKeyName: BRANCH_SCENE_CONFIG_KEY,
            cameraPosition: 'center',
            zoomMode: 'max',
        }
    }
};
