import {
  MessageDetailsMissionSuccessComponent
} from "../../base/custom/dialogs/message-details-mission-success/message-details-mission-success.component";

export const CUSTOM_IMPORTS = [];

export const CUSTOM_DECLARATIONS = [MessageDetailsMissionSuccessComponent];

export const CUSTOM_ENTRY_COMPONENTS = [];

export const CUSTOM_EVENT_DIALOGS = {};
