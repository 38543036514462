import { Component, HostBinding, HostListener, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as moment from "moment";
import { take } from "rxjs/operators";

import { getDPI } from "../../../../../../../scripts/dpi-checker";
import { ApiService } from "../../../../../../core/providers/api.service";
import { RegisterCustomFirebaseTokenService } from "../../../../../../core/services/custom/register-custom-firebase-token.service";
import { PlayerSelectors } from "../../../../../../store/player";
import { selectPrimary } from "../../../../../../store/primary/selectors";
import { GameFeaturesMap } from "../../../../constants/game-features";
import { GameService } from "../../../../services/game.service";
import { MyGame } from "../../../classes/core/MyGame";
import { MyScene } from "../../../classes/core/MyScene";
import { BASIC_BOOT_SCENE } from "../../../scenes-basic/basic.constants";
import { BASIC_SCENE_CONFIG } from "../../../scenes-basic/basic-scene.config";

@Component({
  selector: "app-game-debug",
  templateUrl: "./game-debug.component.html",
})
export class GameDebugComponent implements OnInit {
  expandMap: { [name: string]: boolean } = {
    game: false,
    tileMenu: false,
    worldInfo: false,
    isoToScreen: false,
    tiles: false,
    features: false,
    gameInfo: false,
    currency: true,
  };
  @HostBinding("class.isOpen")
  isOpen = false;
  @HostListener("window:resize", ["$event"]) onResize(event) {
    this.generalInformation.offsetWidth = event.target.innerWidth;
    this.generalInformation.offsetHeight = event.target.innerHeight;
  }

  gameFeaturesMap = GameFeaturesMap;

  sceneConfig = BASIC_SCENE_CONFIG;
  currentScene: MyScene;
  game: MyGame;

  generalInformation = {
    screenWidth: -1,
    screenHeight: -1,
    offsetWidth: -1,
    offsetHeight: -1,
    pixelRatio: "",
    renderType: { mode: 0, text: "" },
    playerId: -1,
    externalId: -1,
    userId: -1,
    dpi: -1,
    branchId: -1,
  };

  // currency and products adder
  currencyList = [];
  productList = [];
  transaction = {
    isProcessing: false,
    currencyName: null,
    currencyAmount: null,
    productName: null,
    productAmount: null,
    addedSuccesfully: false,
    resultText: "",
  };

  constructor(
    private gameService: GameService,
    private store: Store,
    private api: ApiService,
    public registerCustomFirebaseTokenService: RegisterCustomFirebaseTokenService
  ) {}

  showScreenToIsoConverter() {
    this.expandMap.isoToScreen = !this.expandMap.isoToScreen;
  }

  toggle() {
    if (!this.isOpen) {
      this.gatherInformation();
    }

    this.isOpen = !this.isOpen;
  }

  restartScene() {
    this.gameService.game.currentScene.scene.start(BASIC_BOOT_SCENE);
  }

  ngOnInit(): void {
    this.game = this.gameService.game;
    this.gatherInformation();
  }

  gatherInformation() {
    this.getPlayerData();
    this.generalInformation.screenWidth = window.screen.width;
    this.generalInformation.screenHeight = window.screen.height;
    this.generalInformation.offsetWidth = window.innerWidth;
    this.generalInformation.offsetHeight = window.innerHeight;
    this.generalInformation.pixelRatio = window.devicePixelRatio.toFixed(2);
    this.generalInformation.playerId = window["storePlayer"].id;
    this.generalInformation.externalId = window["storeUser"]?.external_id ?? "unknown";
    this.generalInformation.branchId = window["storePlayer"].branch_id;
    this.generalInformation.userId = window["storeUser"].id;
    this.generalInformation.dpi = getDPI();
    this.generalInformation.renderType.mode = window["renderMode"]?.mode ?? "unknown";
    this.generalInformation.renderType.text = window["renderMode"]?.text ?? "unknown";

    if (!this.productList.length || !this.currencyList.length) {
      this.fillTransactionItemsList();
    }
  }

  getPlayerData() {
    this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      window["storePlayer"] = player;
    });
  }

  /* currency code */
  fillTransactionItemsList() {
    this.store
      .select(selectPrimary)
      .pipe(take(1))
      .subscribe(res => {
        this.productList = [];
        this.currencyList = [];

        const productList = JSON.parse(JSON.stringify(res.products));
        const currencyList = JSON.parse(JSON.stringify(res.currencies));

        productList.forEach(x => (x.text = `${x.name} (id: ${x.product_id})`));
        currencyList.forEach(x => (x.text = `${x.name} (id: ${x.currency_id})`));

        this.productList = productList;
        this.currencyList = currencyList;
      });
  }

  triggerNewTransaction(type: "currency" | "product") {
    // prevent triggering function if the transaction is still being processed
    if (this.transaction.isProcessing) {
      return;
    }

    switch (type) {
      case "currency": {
        if (!this.transaction.currencyAmount && this.transaction.currencyName) {
          this.transaction.resultText = `Nie wszystkie pola zostały uzupełnione prawidłowo`;
          this.transaction.addedSuccesfully = false;
          return;
        }

        const currency = this.currencyList.find(x => x.text === this.transaction.currencyName);
        if (!currency) {
          this.transaction.resultText = `Nie znaleziono waluty o nazwie \'${this.transaction.currencyName}\' `;
          this.transaction.addedSuccesfully = false;
          return;
        }

        this.transaction.isProcessing = true;

        this.api
          .post("testing/contribute/currency", { body: { currency_id: currency.currency_id, amount: this.transaction.currencyAmount } })
          .subscribe(
            res => {
              this.transaction.addedSuccesfully = true;
              this.transaction.resultText = `Waluta dodana pomyślnie!`;
            },
            err => {
              this.transaction.addedSuccesfully = false;
              this.transaction.resultText = `Nie udało się dodać waluty. Powód: ${err.message}`;
              this.transaction.isProcessing = false;
            },
            () => {
              this.transaction.isProcessing = false;
              this.transaction.currencyName = null;
              this.transaction.currencyAmount = null;
              setTimeout(() => {
                this.transaction.resultText = "";
              }, 3000);
            }
          );
        break;
      }
      case "product": {
        if (!this.transaction.productAmount && this.transaction.productName) {
          this.transaction.resultText = `Nie wszystkie pola zostały uzupełnione prawidłowo`;
          this.transaction.addedSuccesfully = false;
          return;
        }

        const product = this.productList.find(x => x.text === this.transaction.productName);
        if (!product) {
          this.transaction.resultText = `Nie znaleziono produktu o nazwie \'${this.transaction.productName}\' `;
          this.transaction.addedSuccesfully = false;
          return;
        }

        this.transaction.isProcessing = true;

        this.api
          .post("testing/contribute/product", { body: { product_id: product.product_id, amount: this.transaction.productAmount } })
          .subscribe(
            res => {
              this.transaction.addedSuccesfully = true;
              this.transaction.resultText = `Produkt dodany pomyślnie!`;
            },
            err => {
              this.transaction.addedSuccesfully = false;
              this.transaction.resultText = `Nie udało się dodać produktu. Powód: ${err.message}`;
              this.transaction.isProcessing = false;
            },
            () => {
              this.transaction.isProcessing = false;
              this.transaction.productName = null;
              this.transaction.productAmount = null;
              setTimeout(() => {
                this.transaction.resultText = "";
              }, 3000);
            }
          );
        break;
      }
      default: {
        break;
      }
    }
  }

  showInputArea() {
    this.game.currentScene.board.debugTilesInput();
  }

  showTileInfo() {
    this.game.currentScene.board.debugTiles();
  }

  reloadGame() {
    location.reload();
  }

  sendTestPushMessage(immediately: boolean) {
    if (!window["firebase"]) {
      console.error("[OW_ERR] Nie znaleziono firebase!");
      return;
    }

    const time = moment().format("HH:MM:SS");
    if (immediately) {
      window["firebase"].swRegistration.showNotification("Test push natychmiastowy, wygenerowano - " + time, {
        body: "Test push wiadomosc z DebugTools; natychmiastowy",
        icon: "assets/icons-pwa/icon-48x48.png",
        tag: "firebase-notification",
      });
    } else {
      setTimeout(() => {
        window["firebase"].swRegistration.showNotification("Test push po 15s, wygenerowano - " + time, {
          body: "Test push wiadomosc z DebugTools; natychmiastowy",
          icon: "assets/icons-pwa/icon-48x48.png",
          tag: "firebase-notification",
        });
      }, 15000);
    }
  }

  requestPermission() {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      const notification = new Notification("Permission granted!");
      console.log(notification);
    } else if (Notification.permission !== "denied") {
      console.log("Permission denied, asking for permission...");

      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          const notification = new Notification("Permission granted!");
          console.log(notification);
        }
      });
    } else {
      console.log("Nothing works here");
    }
  }
}
