import { Scope } from "../../interfaces/custom/scope.interface";
import { Task } from "../../interfaces/custom/task.interface";

export function setBaseScopeHelper(task: Task): Scope {
  const isDefaultScope = task.scopes.find(scope => scope.default);
  const isTarget100 = task.scopes.find(scope => scope.target === 1);
  const fallback = task.scopes.length ? task.scopes[0] : null;

  return isDefaultScope || isTarget100 || fallback;
}
