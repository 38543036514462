import { Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../../../core/providers/synchronize-time.service";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../../core/utility/ow-pagination.class";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { ApiBusinessQuestionService } from "../../../../api/custom/services/api-business-question.service";
import { Question, QuestionCategory } from "../../../../interfaces/custom/question.interface";
import { QuestionComponent } from "../question/question-component";

@Component({
  selector: "app-question-list",
  templateUrl: "./question-list.component.html",
})
export class QuestionListComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiBusinessQuestionService) apiBusinessQuestionService: ApiBusinessQuestionService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(PlayerService) playerService: PlayerService;

  _owDate: OwDate = new OwDate();
  owPaginationDate: OwPaginationDate;

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.setPagination();
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  STOCK_VIEW = STOCK_VIEW;

  questions: Question[];
  categories: QuestionCategoryFilter[];
  activeCategory: QuestionCategoryFilter;
  categoryAll: QuestionCategoryFilter = {
    name: null,
    label: "Wszystko",
    order: 0,
  };
  customLoading: boolean;
  timerRefreshTimeString;

  ngOnInit() {
    this.owDate = this.getOwDateByRealTime();
    this.getQuestionsList();
  }

  getOwDateByRealTime() {
    const playerTime = moment(this.playerService.getRealTime());

    return new OwDate({
      year: playerTime.year(),
      month: playerTime.month() + 1,
    });
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
      end: this.getOwDateByRealTime(),
    });
  }

  next() {
    this.owDate.add({ month: 1 });
    this.getQuestionsList();
  }

  prev() {
    this.owDate.subtract({ month: 1 });
    this.getQuestionsList();
  }

  getQuestionsList() {
    this.customLoading = true;
    this.setPagination();
    this.clearTimer();

    this.apiBusinessQuestionService.getQuestionsList({ year: this.owDate.year, month: this.owDate.month }).subscribe(
      resp => {
        this.questions = resp;

        this.setCategories();
        this.changeCategory(this.categoryAll);
        this.setMomentString();
        this.setTimer();
        this.customLoading = false;
      },
      () => {
        this.customLoading = false;
      }
    );
  }

  setMomentString() {
    this.questions.forEach(question => {
      const currentTime = moment(this.playerService.getRealTime());
      question.diffTimeSeconds = moment(question.expires_at).diff(currentTime, "seconds");
      question.is_expired = currentTime.isAfter(new Date(question.expires_at));
    });
  }

  setTimer() {
    this.timerRefreshTimeString = setTimeout(() => {
      this.setMomentString();
      this.setTimer();
    }, 995);
  }

  clearTimer() {
    clearTimeout(this.timerRefreshTimeString);
  }

  setCategories() {
    const nonUniqCategories = this.questions.map(question => {
      return { ...question.category, label: question.category.name };
    });
    const uniqCategories = R.uniqBy(R.prop("name"), nonUniqCategories);
    this.categories = R.sortWith([R.ascend(R.prop("order"))], [...uniqCategories, this.categoryAll]);
  }

  changeCategory(category: QuestionCategoryFilter) {
    this.activeCategory = category;
  }

  openQuestion(question) {
    if (question.is_expired && !question.answered_at) {
      this.dialogService.openAlert({
        description: "Czas na pytanie upłynął.",
      });

      return;
    }

    this.dialogService.open(
      QuestionComponent,
      {
        data: {
          question: question,
          fromList: true,
        },
        disableClose: true,
      },
      reload => {
        if (reload) {
          this.getQuestionsList();
        }
      }
    );
  }

  ngOnDestroy() {
    this.clearTimer();
  }
}

interface QuestionCategoryFilter extends QuestionCategory {
  label: string;
}
