import { Component, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BusinessRanksService } from "../../../../services/custom/business-ranks.service";
import { RankGame } from "../../../../interfaces/custom/rank-game.interface";

@Component({
  selector: "app-ranking-wolrd-details-filter",
  templateUrl: "./ranking-wolrd-details-filter.component.html",
})
export class RankingWolrdDetailsFilterComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(BusinessRanksService) businessRanksService: BusinessRanksService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<any>;
  @OwInject(MAT_DIALOG_DATA) data: {
    rank: RankGame;
  };
  branchList: Branch[] = [];

  ngOnInit() {
    this.initBranchList();
  }

  initBranchList() {
    this.businessRanksService.ranksWorldAllBranches.forEach(branch => {
      this.branchList.push({
        selected: this.businessRanksService.ranksWorldSelectedBranches.includes(branch),
        branchName: branch,
      });
    });
    this.sortBranchAlphabetic();
    this.sortBranchSelectedBranch();
    this.sortSpecialBranch();
  }

  sortBranchAlphabetic() {
    this.branchList.sort((a, b) => a.branchName.localeCompare(b.branchName));
  }
  sortBranchSelectedBranch() {
    this.branchList.sort((a, b) => (a.selected ? -1 : 0));
  }
  sortSpecialBranch() {
    this.branchList.sort((a, b) => {
      let value = 0;
      Object.keys(this.businessRanksService.SPECIAL_BRANCHES).forEach(key => {
        if (this.businessRanksService.SPECIAL_BRANCHES[key].name === a.branchName) value = -1;
      });
      return value;
    });
  }

  selectBranch(branch: Branch) {
    this.branchList.forEach(branch => (branch.selected = false));
    branch.selected = true;
  }

  filterBranch() {
    const selectedBranches = [];
    this.branchList.forEach(branch => {
      if (branch.selected) {
        selectedBranches.push(branch.branchName);
      }
    });
    this.businessRanksService.ranksWorldSelectedBranches = [...selectedBranches];
    this.businessRanksService.saveSelectedBranchesInLocalStorage(this.data.rank);
    this.matDialogRef.close();
  }
}
interface Branch {
  selected: boolean;
  branchName: string;
}
