import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { RankingDetailsGame } from "../../base/custom/dialogs/ranking-game-details/ranking-details-game.component";
import { RankingListComponent } from "../../base/custom/dialogs/ranking-list/ranking-list.component";
import { RankingDetailsWorld } from "../../base/custom/dialogs/ranking-world-details/ranking-details-world.component";
import { RankingInformationsDashboard } from "../../base/custom/dialogs/ranking-informations-dashboard/ranking-informations-dashboard.component";
import { RankingWolrdDetailsFilterComponent } from "../../base/custom/dialogs/ranking-wolrd-details-filter/ranking-wolrd-details-filter.component";

@NgModule({
  declarations: [
    RankingListComponent,
    RankingDetailsGame,
    RankingDetailsWorld,
    RankingInformationsDashboard,
    RankingWolrdDetailsFilterComponent,
  ],
  imports: [CommonModule, SharedModule, SharedUiMobileModule, OwPerfectScrollbarModule],
})
export class RankingsModule {}
