// import { HudResourcesComponent } from '../../../../custom/basic/components/hud-resources/hud-resources.component';
// import { HudMenuComponent } from '../../../../custom/basic/components/hud-menu/hud-menu.component';
// import { BusinessCardsComponent } from '../../../../../business/basic/custom/components/business-cards/business-cards.component';
// import { HudBranchSceneComponent } from '../../../../custom/basic/components/hud-branch-scene/hud-branch-scene.component';
// import { HudParameters2Component } from '../../../../custom/basic/components/hud-parameters/hud-parameters.component';

import { BusinessCardsComponent } from "../../../../../business/basic/custom/components/business-cards/business-cards.component";
import { HudMenuComponent } from "../../../../base/custom/components/hud-menu/hud-menu.component";
import { HudParameters2Component } from "../../../../base/custom/components/hud-parameters/hud-parameters.component";
import { HudResourcesComponent } from "../../../../base/custom/components/hud-resources/hud-resources.component";

export const CUSTOM_DYNAMIC_COMPONENTS = {
  HUD_RESOURCES: {
    component: HudResourcesComponent,
    viewContainerRef: "hudResourcesTpl",
  },
  HUD_MENU: {
    component: HudMenuComponent,
    viewContainerRef: "hudMenuTpl",
  },
  BUSINESS: {
    component: BusinessCardsComponent,
    viewContainerRef: "dynamicsComponentTpl",
  },
  // HUD_BRANCH_SCENE: {
  //   component: HudBranchSceneComponent,
  //   viewContainerRef: 'dynamicsComponentTpl',
  // },
  HUD_PARAMETERS: {
    component: HudParameters2Component,
    viewContainerRef: "hudParametersTpl",
  },
};
