import { Component, OnDestroy, OnInit } from "@angular/core";

import { AbstractBuildingUpgradeComponent } from "../../../../abstract/core/abstract-building-upgrade.component";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";

@Component({
  selector: "app-building-upgrade-custom",
  templateUrl: "./building-upgrade.component.html",
})
export class BuildingUpgradeComponent extends AbstractBuildingUpgradeComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
