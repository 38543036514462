import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { ResetBoard } from "../../../../../store/game/actions";
import { MyGame } from "../../classes/core/MyGame";
import { BasicBootScene } from "../core/Basic.boot.scene";

export class CustomBasicBootScene extends BasicBootScene {
    preload(params): void {
        this.sceneConfig = this.provideSceneConfig();
        this.gameService = (this.game as MyGame).gameService;
        this.gameService.guiService.isSplashShow.next(true);

        this.gameService.store.dispatch(new ResetBoard());

        if (this.passedData && this.passedData.playerIslandId) {
            this.gameService.prepareGameData(this.gameService.playerService.player.id, this.passedData.playerIslandId);
        } else {
            this.gameService.boardService
                .getWorld(this.gameService.playerService.getActivePlayerId())
                .pipe(
                    mergeMap(worldData => {
                        let worldIsland;
                        worldData.find(
                            regionData => (worldIsland = regionData.islands.find(island => island.group_id === this.sceneConfig.islandGroupIdForBoard))
                        );
                        if (worldIsland) {
                            this.gameService.prepareGameData(this.gameService.playerService.player.id, worldIsland.player_island_id);
                        }

                        return of(worldIsland);
                    })
                )
                .subscribe(console.log);
        }

        this.provideAllScenes();
    }
}
