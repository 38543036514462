<div class="global-dialog ow-dialog window-a primary no-transparent">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <loading></loading>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      Pytania codzienne
    </div>
  </div>

  <div class="container loading-opacity" *ngIf="questions?.length">
    <ow-perfect-scrollbar [scrollGradient]="questions">
      <table>
        <thead>
        <tr>
          <th></th>
          <th>Nagroda</th>
          <th
            [matTooltip]="'Data ważności pytania'"
            [matTooltipPosition]="'above'"
          >
            Data wygaśnięcia
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let question of questions">
          <tr
            *ngIf="activeCategory.name ? question.category.name === activeCategory.name: true"
            [class.opacity]="question.is_expired || question.answered_at"
            (click)="openQuestion(question)"
          >
            <td><span [innerHTML]="question.title"></span></td>
            <td>
              <div class="flex-center prizes">
                <m-ui-currency
                  *ngFor="let currency of question.prizes.currency_prizes"
                  [item]="currency"
                  [lack]="false"
                  [stockView]="STOCK_VIEW.J"
                ></m-ui-currency>

                <m-ui-product
                  *ngFor="let product of question.prizes.product_prizes"
                  [item]="product"
                  [lack]="false"
                  [stockView]="STOCK_VIEW.J"
                  [lockOpenStorage]="true"
                ></m-ui-product>
              </div>
            </td>

            <td
              [matTooltip]="question.expires_at | date:'dd-MM-y HH:mm:ss'"
              [matTooltipPosition]="'above'"
              class="no-wrap"
            >
              {{ (question.is_expired || question.answered_at) ? (question.expires_at | date:'dd-MM-y') : (question.diffTimeSeconds | toHour) }}
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </ow-perfect-scrollbar>
  </div>
  <div class="daily-questions-empty-page" *ngIf="!questions?.length && !customLoading">
    Brak pytań w wybranym okresie
  </div>


  <!-- NAVIGATION -->
  <ng-container *ngIf="owPaginationDate">
    <div class="nav">
      <!-- PREV -->
      <button
        class="square small primary prev"
        (click)="prev()"
        [disabled]="!owPaginationDate.allowPrev.month"
      >
        <i class="fas fa-chevron-left"></i>
      </button>

      <!-- CURRENT -->
      <span class="month">
          <loading
            [classNames]="['white', 'small']"
            [customLogicShow]="customLoading"
          ></loading>
          <div *ngIf="!customLoading">
            {{ owPaginationDate.current.month_text_normal }} {{ owPaginationDate.current.year }}
          </div>
        </span>

      <!-- NEXT -->
      <button
        class="square small primary next"
        (click)="next()"
        [disabled]="!owPaginationDate.allowNext.month"
      >
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </ng-container>
</div>
