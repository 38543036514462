import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../../../../shared/shared.module";
// import { RankingsModule } from "../../../rankings/module/custom/rankings.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { BasicActionsComponent } from "../../basic/custom/components/basic-actions/basic-actions.component";
import { BusinessCardsComponent } from "../../basic/custom/components/business-cards/business-cards.component";
import { CardTaskComponent } from "../../basic/custom/components/card-task/card-task.component";
import { CardTaskFakeComponent } from "../../basic/custom/components/card-task-fake/card-task-fake.component";
import { CardTaskDetailsComponent } from "../../basic/custom/dialogs/card-task-details/card-task-details.component";
import { ChooseTaskComponent } from "../../basic/custom/dialogs/choose-task/choose-task.component";
import { QuestionComponent } from "../../basic/custom/dialogs/question/question-component";
import { QuestionListComponent } from "../../basic/custom/dialogs/question-list/question-list.component";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";
import { RewardCodesComponent } from "../../basic/custom/dialogs/reward-codes/reward-codes.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  imports: [CommonModule, SharedUiMobileModule, SharedModule, OwPerfectScrollbarModule, ReactiveFormsModule],
  declarations: [
    CardTaskComponent,
    CardTaskDetailsComponent,
    BusinessCardsComponent,
    ChooseTaskComponent,
    BasicActionsComponent,
    QuestionComponent,
    QuestionListComponent,
    RewardCodesComponent,
    CardTaskFakeComponent,
  ],
  exports: [BusinessCardsComponent],
})
export class BusinessModule {}
