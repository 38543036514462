import { CardTask } from "../../interfaces/custom/card-task.interface";
import { setBaseScopeHelper } from "./set-base-scope.helper";
import { setDailyHelper } from "./set-daily.helper";
import { setPaidPrizesHelper } from "./set-paid-prizes.helper";
import { setPrizesToDisplayHelper } from "./set-prizes-to-display.helper";
import { setProgressbarHelper } from "./set-progressbar.helper";
import { setRealizationScopeHelper } from "./set-realization-scope.helper";
import { setScopesHelper } from "./set-scopes.helper";
import { setTaskClasses } from "./set-task-classes.helper";

export function prepareCardTask(cardTasks: CardTask[]): CardTask[] {
  return cardTasks.map(cardTask => {
    if (cardTask.selected) {
      cardTask.selected.taskClasses = setTaskClasses(cardTask.selected);
      cardTask.selected = setScopesHelper(cardTask.selected);
      cardTask.selected.baseScope = setBaseScopeHelper(cardTask.selected);
      cardTask.selected.progress = setProgressbarHelper(cardTask.selected);
      cardTask.selected.prizes_reference = setPrizesToDisplayHelper(cardTask.selected);
      cardTask.selected.prizes_paid = setPaidPrizesHelper(cardTask.selected);
    }

    if (cardTask.other_tasks) {
      cardTask.other_tasks.forEach(task => {
        task.taskClasses = setTaskClasses(task);
        task = setScopesHelper(task);
        task.baseScope = setBaseScopeHelper(task);
        task.progress = setProgressbarHelper(task);
        task.realizationScope = setRealizationScopeHelper(task);
      });
    }

    cardTask = setDailyHelper(cardTask);

    return cardTask;
  });
}
