import { Task } from '../../interfaces/custom/task.interface';

export function setScopesHelper(task: Task): Task {
  const isS2 = task.taskClasses.some(className => className === 'card-type-s2');

  if (isS2) {
    task.scopes.forEach((scope) => {
      scope.target *= task.target;
    });
  }

  return task;
}
