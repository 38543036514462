<div class="wrapper">
  <!-- LOGO -->
  <div class="logo"></div>

  <loading></loading>

  <div class="content-wrapper form">
    <form [formGroup]="form" *ngIf="form">
      <!-- USERNAME -->
      <ng-container *ngIf="form.get('username') as username">
        <div class="control" [class.error]="getErrors(username)">
          <div class="label-flex">
            <label>Twój Nick (widoczny będzie w rankingach)</label>
          </div>
          <input class="control-input" formControlName="username" type="text" (paste)="onPaste($event)" />
          <div class="alert alert-danger" *ngIf="getErrors(username)">
            <span *ngIf="username.errors.required">Pole wymagane</span>
          </div>
        </div>
      </ng-container>

      <div class="confirmations">
        <div class="control control-checkbox" [class.error-checkbox]="getDirty(form.get('confirm'))">
          <div class="custom-checkbox">
            <input type="checkbox" formControlName="confirm" id="confirm" required  />
            <span class="custom-checkbox-fill">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <label for="confirm"> Akceptuję <a [href]="'https://stocktown.pl/api/files/download/ea5165f8-1faf-4581-9ccb-2056829a9067/Regulamin%20STOCK%20TOWN.pdf'" target="_blank">regulamin</a> </label>
        </div>

        <div class="control control-checkbox" [class.error-checkbox]="getDirty(form.get('confirmRodo'))">
          <div class="custom-checkbox">
            <input type="checkbox" formControlName="confirmRodo" id="confirmRodo" required />
            <span class="custom-checkbox-fill">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <label for="confirmRodo"> Akceptuję <a [href]="'https://stocktown.pl/api/files/download/978b5f0e-44db-4f03-8d9e-5347f002cbdf/regulamin%20RODO.pdf'" target="_blank">klauzulę RODO</a> </label>
        </div>
      </div>
    </form>

    <div class="buttons" *ngIf="form">
      <button class="auth-button primary base" (click)="registrationConfirm()" [disabled]="form.invalid">Zarejestruj</button>
    </div>
  </div>
</div>
