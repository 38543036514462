<div class="landing-background">
  <div class="landing-content-border">
    <div class="landing-content">
      <div class="stock-logo"></div>
      <video class="video additional-space-80" width="1600" height="900" controls>
        <source src="/main/assets/other/landing/stock.mp4" type="video/mp4">
      </video>
      <div class="text-two">
        NA CZYM POLEGA ZABAWA<br />
        W NOWEJ GRYWALIZACJI<br />
      </div>
      <div class="image-one"></div>
      <div class="text-container">
        <div class="text-inside side-text">
          Realizuj zadania<br />
          i zdobywaj banknoty!
        </div>
        <div class="text-inside center-text">
          Rozbuduj miasto<br />
          za banknoty<br />
          i spraw, że wśród <br />
          mieszkańców<br />
          zapanuje team spirit!
        </div>
        <div class="text-inside side-text">
          Wygraj nagrody<br />
          w rankingach!
        </div>
      </div>
      <div class="text-golden additional-space-21">IM LEPIEJ ZREALIZUJESZ ZADANIA, TYM WIĘCEJ OTRZYMASZ BANKNOTÓW.</div>
      <div class="text-casual additional-space-87">
        Za realizację zadań biznesowych możesz zyskać około 70% banknotów,<br />
        za zadania edukacyjne w aplikacji około 20%, a za realizowanie<br />
        przemyślanej strategii rozbudowy miasta w grze około 10%.
      </div>
      <div class="text-three">Sprawdź, jakie nagrody czekają na zwycięzców:</div>
      <div class="text-golden">PULA NAGRÓD TO PONAD 1 200 000 zł!</div>
      <div class="text-separator"></div>
      <div class="text-golden">CO KWARTAŁ PONAD 50 NAGRODZONYCH OSÓB!</div>
      <div class="text-separator"></div>
      <div class="text-golden">
        WYJAZD DO MEKSYKU <br />
        JAKO NAGRODA DLA NAJLEPSZYCH GRACZY!
      </div>
      <div class="text-separator"></div>
      <div class="text-golden" [class.additional-space-88]="timer>=0" [class.additional-space-190]="!(timer>=0)">
        PONAD 40 000 ZŁ<br />
        DO ROZDANIA W KAŻDYM KWARTALE!
      </div>
      <ng-container *ngIf="timer>=0; else gameLaunched">
        <div class="text-five">
          ZOSTAŃ BURMISTRZEM<br/>
          STOCK TOWN JUŻ ZA:
        </div>
        <div class="text-casual-bold">{{convertTime(timer)}}</div>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #gameLaunched>
  <div class="text-casual-bold additional-space-37">
    PRZEJMIJ STERY<br />
    W STOCK TOWN JUŻ TERAZ:
  </div>
  <div class="buttons-container">
    <button class="enter-game" (click)="login()">WEJDŹ DO GRY</button>
    <button class="install-app" *ngIf="a2hsService.showAddToHomeScreenButton" (click)="addApplicationToHomeScreen()">ZAINSTALUJ APLIKACJĘ</button>
  </div>
</ng-template>
