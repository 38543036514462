import { Component, OnInit } from "@angular/core";

import { AbstractRankDetailsGameComponent } from "../../../../abstract/custom/abstract-rank-game-details.component";
import { RankingInformationsDashboard } from "../ranking-informations-dashboard/ranking-informations-dashboard.component";
import { GetGameRankResponse } from "../../../../api/custom/interfaces/response/get-game-rank.response.interface";
import { tap } from "rxjs/operators";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { GetGameRankResultsResponse } from "../../../../api/custom/interfaces/response/get-game-rank-results.response.interface";
import { BusinessRanksService } from "../../../../services/custom/business-ranks.service";
import { UserActions } from "../../../../../../../../store/user";

@Component({
  selector: "app-ranking-details-world",
  templateUrl: "./ranking-details-world.component.html",
})
export class RankingDetailsWorld extends AbstractRankDetailsGameComponent implements OnInit {
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(BusinessRanksService) businessRanksService: BusinessRanksService;
  displayFilter = false;
  branchList = new Set<string>();

  ngOnInit() {
    this.getGameRank();
  }

  getGameRank() {
    this.apiCoreGameRanksService
      .getGameRank({
        rank_edition_id: this.data.rankId,
      })
      .pipe(tap(this.checkDisplayFilterParameter.bind(this)))
      .subscribe(
        resp => {
          this.rank = resp;
          this.getGameRanksResults();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  checkDisplayFilterParameter(rank: GetGameRankResponse) {
    const parameters = rank?.parameters?.enable_branch_filter_for_role;
    if (parameters?.length > 0) {
      this.displayFilter = parameters.includes(this.playerService.player.title_id.id);
    } else {
      this.displayFilter = false;
    }
  }

  getGameRanksResults() {
    this.apiCoreGameRanksService
      .getGameRanksResults({
        rank_edition_id: this.rank.rank_edition_id,
      })
      .pipe(tap(this.initBranchesForFiltering.bind(this)))
      .subscribe(
        resp => {
          this.rankResults = resp;
          this.displaySelectedBranches();
        },
        errResp => {
          this.dialogService.openAlertErrorApi({ errResp });
        }
      );
  }

  initBranchesForFiltering(rank: GetGameRankResultsResponse) {
    rank.results.forEach(result => {
      this.branchList.add(result.branch_name);
    });
    this.businessRanksService.initSelectedBranch(this.rank, [this.businessRanksService.SPECIAL_BRANCHES.WSZYSTKIE.name]);
    this.businessRanksService.initAllBranch([...this.branchList]);
  }

  openFilter() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_WORLD_FILTER,
      config: {
        data: {
          rank: this.rank,
        },
      },
      callback: this.displaySelectedBranches.bind(this),
    });
  }

  displaySelectedBranches() {
    let branchToDisplay = [];
    const specialBranch = this.businessRanksService.selectedIncludeSpecial();
    if (specialBranch) {
      branchToDisplay = this.businessRanksService.SPECIAL_BRANCHES[specialBranch].branchesToDisplay();
    } else {
      branchToDisplay = this.businessRanksService.ranksWorldSelectedBranches;
    }
    this.rankResults.results.forEach(result => {
      if (branchToDisplay.includes(result.branch_name)) {
        result.filtered = false;
      } else {
        result.filtered = true;
      }
    });
  }

  openRankInformationDashboard() {
    this.dialogService.dialog.open(RankingInformationsDashboard);
  }
}
