import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractHudResourcesComponent } from "../../../../abstract/core/abstract-hud-resources.component";
import { GuiItem } from "../../../../interfaces/core/gui-item.interface";

@Component({
  selector: "app-hud-resources-custom",
  templateUrl: "./hud-resources.component.html",
})
export class HudResourcesComponent extends AbstractHudResourcesComponent implements OnInit, OnDestroy {
  // @OwInject(ChangeMapService) changeMapService: ChangeMapService;

  GROUP_ONE: GuiItemFilter[] = [
    { currency_id: 1 },
    { currency_id: 2 },
    { currency_id: 3 },
    { currency_id: 4 },
    { currency_id: 5 },
    { product_id: 200 },
  ];

  GROUP_TWO: GuiItemFilter[] = [
    { currency_id: 1 },
    { currency_id: 2 },
    { currency_id: 4 },
    { product_id: 200 },
    { product_id: 711 },
    { currency_id: 7 },
  ];

  sceneKey: string;
  isBranchMap: boolean;

  guiItemsFiltered: GuiItem[] = [];

  ngOnInit() {
    this.subscribeUser();
    this.setParameters();
  }
  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  // subscribeSceneState() {
  //   this.subs["scene"] = this.store
  //     .select(selectCurrentScene)
  //     .pipe(filter(Boolean))
  //     .subscribe((currentScene: string) => {
  //       this.sceneKey = currentScene;
  //       const sceneOtherConfig = R.path(["sceneConfig", "other"], this.gameService.game.currentScene) as unknown;
  //       this.isBranchMap = sceneOtherConfig?.isBranchMap;
  //
  //       this.handleResourcesBranchMap();
  //     });
  // }

  // subscribeGlobal() {
  //   this.subs["global"] = this.changeMapService.subscribeGlobalEvents(() => {
  //     this.orderGuiItems();
  //   });
  // }

  // handleResourcesBranchMap() {
  //   if (this.isBranchMap) {
  //     this.guiItemsFiltered = this.guiItems.filter(i => i.currency_id === 1 || i.currency_id === 2);
  //   }
  // }

  orderGuiItems() {
    super.orderGuiItems();
    // this.filterGuiItems();
  }

  // filterGuiItems() {
  //   const group = this.changeMapService.isTreasuresScene ? this.GROUP_TWO : this.GROUP_ONE;
  //   this.guiItemsFiltered = this.guiItems.filter(guiItem => {
  //     if (guiItem.currency_id) {
  //       return R.includes(
  //         guiItem.currency_id,
  //         group.map(x => x.currency_id)
  //       );
  //     }
  //
  //     if (guiItem.product_id) {
  //       return R.includes(
  //         guiItem.product_id,
  //         group.map(x => x.product_id)
  //       );
  //     }
  //   });
  // }
}

interface GuiItemFilter {
  currency_id?: number;
  product_id?: number;
}
