import { Component, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { MatDialogRef } from "@angular/material/dialog";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { RankMoneyInfo } from "../../../../interfaces/custom/ranks.interface";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";

@Component({
  selector: "app-ranking-informations-dashboard",
  templateUrl: "./ranking-informations-dashboard.component.html",
})
export class RankingInformationsDashboard extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<RankingInformationsDashboard>;
  @OwInject(PlayerService) playerService: PlayerService;

  moneyInfo: RankMoneyInfo;

  ngOnInit() {
    this.moneyInfo = this.playerService.player["rank_money_info"] ?? { edu_earnings: 0, business_earning: 0, balance: 0 };
  }

  close() {
    this.matDialogRef.close();
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
