import { Component, OnDestroy, OnInit } from "@angular/core";
import { select } from "@ngrx/store";
import { filter, take } from "rxjs/operators";

import { orientationModes } from "../../../../../../core/consts/core/orientation-modes.enum";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import {
  BoardTileUpdate,
  NewImportantMessageClosed,
  NewImportantMessageOpened,
  NewImportantMessageReceived,
  ResetBoard,
} from "../../../../../../store/game/actions";
import { ImportantMessagesState } from "../../../../../../store/game/interfaces/ImportantMessages.state";
import { selectGameReady, selectImportantMessages } from "../../../../../../store/game/selectors";
import { UtilitySelectors } from "../../../../../../store/utility";
import { handleMessageDetailsType } from "../../../../../game/game-ui/message/helpers/core/message.helper";
import { MessageEventFromDetails } from "../../../../../game/game-ui/message/interfaces/core/message-callback-from-details.interface";
import { MessageOpenDetailsData } from "../../../../../game/game-ui/message/interfaces/core/message-open-details-data.interface";
import { MessageService } from "../../../../../game/game-ui/message/services/core/message.service";
import { AbstractPlayGameComponent } from "../../../../abstract/core/abstract-play-game.component";
import { GlobalEvent } from "../../../../../../core/interfaces/shared.interfaces";
import { EVENTS } from "../../../../../../core/consts/core/events";
import { UpdateUnreadMessages } from "../../../../../../store/player/actions";

@Component({
  selector: "app-play-game",
  templateUrl: "./play-game.component.html",
})
export class PlayGameComponent extends AbstractPlayGameComponent implements OnInit, OnDestroy {
  @OwInject(MessageService) messagesService: MessageService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  ngOnInit() {
    this.androidService.setNativeAppOrientation(orientationModes.Landscape);
    this.setDynamicsComponents();
    this.gameService.guiService.isSplashShow.next(true);
    this.updateUserOnce();
    this.updatePlayerOnce();

    this.subs.importantMessages = this.store
      .pipe(
        select(selectImportantMessages),
        filter(state => state.messages.length && !state.importantMessageDisplayed),
        filter(() => !this.guiService.isLockOpenDialog)
      )
      .subscribe((importantMessages: ImportantMessagesState) => {
        const message = importantMessages.messages[0];
        this.store.dispatch(new NewImportantMessageOpened(true));
        const messageType = handleMessageDetailsType(message);
        const eventDialog = this.messagesService.handleMessageDetailsComponent(messageType);

        const messageOpenDetailsData: MessageOpenDetailsData = {
          message: message,
          fromList: false,
        };

        this.eventEmitterDialogsService.emitter.emit({
          name: eventDialog,
          config: {
            data: messageOpenDetailsData,
            disableClose: true,
          },
          callback: (event: MessageEventFromDetails) => {
            this.store.dispatch(new NewImportantMessageClosed(message));
          },
        });
      });

    this.gameReady$ = this.store.pipe(select(selectGameReady));

    this.subs.other = this.gameService.globalService.globalEvents.subscribe(this.handleGlobalEvent.bind(this));
  }

  handleGlobalEvent(event: GlobalEvent) {
    switch (event.name) {
      case EVENTS.GLOBAL.NEW_IMPORTANT_MESSAGE:
        this.store.dispatch(new NewImportantMessageReceived(event.value));
        break;
      case EVENTS.GLOBAL.TILE_CHANGED:
        // OVVERRIDE CORE FILE START
        const tile = event.value;
        if (tile.branch_building) {
          const building = tile.branch_building;
          building.player_mission = building.branch_mission;
          building.player_building_id = building.branch_building_id;
          tile.player_building = building;
          tile.player_tile_id = -1 * tile.tile_id;
        }
        // OVVERRIDE CORE FILE END
        this.store.dispatch(new BoardTileUpdate(event.value));
        break;
      case EVENTS.GLOBAL.UNREAD_COUNT:
        this.store.dispatch(new UpdateUnreadMessages(event.value));
        break;
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
    clearTimeout(this.inactivityTimeout);
    this.store.dispatch(new ResetBoard());
    this.androidService.setNativeAppOrientation(orientationModes.Auto);
  }
}
