<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button
    *ngIf="isDetailsView"
    [preventDefault]="true"
    (clicked)="toggleDetailsView()"
  ></m-ui-back-button>

  <ng-container *ngIf="mission">
    <div class="top" [class.details-view]="isDetailsView">
      <div class="sender-icon">
        <img [src]="mission.icon + '.png' | asset:'missions/board-main'"/>
      </div>
      <div
        *ngIf="!isDetailsView"
        class="title"
        [innerHTML]="mission.title"
      ></div>
    </div>

    <ng-container *ngIf="!isDetailsView">
      <div class="description" [innerHTML]="mission.description | safe:'html'" owEllipsis></div>

      <div class="mark-window-left">
        <m-ui-currency
          [item]="contributionCurrency"
          [stockView]="STOCK_VIEW.B"
          [lack]="false"
        ></m-ui-currency>
      </div>

      <m-ui-separator
      [class.fill-empty]="(!mission.currency_prizes?.length || mission.currency_prizes?.length === 1) && !mission.product_prizes?.length"
      ></m-ui-separator>

      <ng-container
        *ngIf="mission.currency_prizes?.length > 1 || mission.product_prizes?.length"
      >
        <div class="container">
          <span class="title">Twoje nagrody za realizację misji</span>
          <div class="prizes">
            <ng-container *ngFor="let currency of mission.currency_prizes">
              <m-ui-currency
                *ngIf="currency.currency_id !== 2"
                [item]="currency"
                [stockView]="STOCK_VIEW.B"
                [lack]="false"
              ></m-ui-currency>
            </ng-container>

            <m-ui-product
              *ngFor="let product of mission.product_prizes"
              [item]="product"
              [stockView]="STOCK_VIEW.B"
              [lack]="false"
            ></m-ui-product>
          </div>
        </div>

        <m-ui-separator></m-ui-separator>
      </ng-container>
    </ng-container>

    <div class="container">
      <div class="info" *ngIf="!isDetailsView">
        <span>Wspieraj zespół w realizacji misji, aby móc odebrać nagrody</span>

        <i
          class="fas fa-info-square"
          (click)="toggleDetailsView()"
        ></i>
      </div>

      <div class="progress-block">
        <div class="icon-progress">
          <m-ui-currency
            *ngIf="mission.currency"
            [item]="mission.currency"
            [stockView]="STOCK_VIEW.B"
            [lack]="false"
            [hideValue]="true"
          ></m-ui-currency>

          <m-ui-product
            *ngIf="mission.product"
            [item]="mission.product"
            [stockView]="STOCK_VIEW.B"
            [lack]="false"
            [hideValue]="true"
          ></m-ui-product>
        </div>

        <div class="progress-container">
          <div
            class="fill one"
            [style.width]="((mission.handed_over * 100) /  mission.target) + '%'"
          ></div>

          <span class="value">
            {{ mission.handed_over }}/{{ mission.target }}
          </span>
        </div>

        <button
          class="base secondary transfer"
          (click)="openMissionTransfer()"
          [disabled]="mission.status === 1"
        >
          Przekaż
        </button>
      </div>

      <div class="info" *ngIf="!isDetailsView">
        Misja zaliczy się automatycznie po spełnieniu warunku
      </div>
    </div>

    <ng-container *ngIf="isDetailsView && missionBranchPlayers">
      <table class="thead">
        <thead>
        <tr>
          <td></td>
          <td></td>
          <td>wkład indywidualny</td>
        </tr>
        </thead>
      </table>
      <div class="table-2">
        <perfect-scrollbar>
          <table class="tbody">
            <tbody>
            <tr
              *ngFor="let branchPlayer of missionBranchPlayers; let idx = index"
              [class.other-branch]="branchPlayer.is_other_branch"
            >
              <td>{{ idx + 1 }}</td>
              <td>{{ branchPlayer.first_name }} {{ branchPlayer.last_name }}</td>
              <td>
                <ng-container *ngIf="!branchPlayer.is_other_branch; else otherBranchTpl">
                  <ng-container *ngIf="branchPlayer.level >= 10">
                    {{ branchPlayer.amount }}
                  </ng-container>
                  <ng-container *ngIf="branchPlayer.level > 0 && branchPlayer.level < 10">
                    Zbyt niski poziom
                  </ng-container>
                  <ng-container *ngIf="branchPlayer.level === 0">
                    Nieaktywny w grze
                  </ng-container>
                </ng-container>

                <ng-template #otherBranchTpl>
                  Zmiana zespołu
                </ng-template>
              </td>
            </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
    </ng-container>
  </ng-container>
</div>
