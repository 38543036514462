<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="closeOrCloseAll()"
  ></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"
  ></m-ui-back-button>

  <!-- IF MESSAGE EXIST -->
  <div class="height-100 relative" *ngIf="message">
    <loading></loading>

    <div class="flex-container loading-opacity">
      <div class="hidden-reward-container">
        <!-- MISSION ICON -->
        <div class="hidden-reward-image">
          <img [src]="(message.parameters.mission_icon + '.png' | asset:'missions/board-main')"/>
          <span>Gratulacje!</span>
        </div>

        <!-- TITLE -->
        <div class="title" [innerHTML]="message.title | safe:'html'"></div>

        <!-- DESCRIPTION -->
        <div class="inner-html-description" [innerHTML]="message.description | safe:'html'"></div>

        <!-- PRIZES EXPERT CITY-->
        <ng-container *ngIf="message.parameters.currencies.length || message.parameters.products.length">

          <div class="parameters">
            <!-- CURRENCIES -->
            <ng-container *ngFor="let currency of message.parameters.currencies">
              <m-ui-currency
                [item]="currency"
                [stockView]="STOCK_VIEW.B"
                [lack]="false"
              ></m-ui-currency>
            </ng-container>

            <!-- PRODUCTS -->
            <m-ui-product
              *ngFor="let product of message.parameters.products"
              [item]="product"
              [stockView]="STOCK_VIEW.B"
              [lack]="false"
            ></m-ui-product>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- MESSAGE TRASH -->
    <i
      class="fas fa-archive trash-icon"
      *ngIf="!message.deleted && message.deletable"
      (click)="moveToTrash()"
      [matTooltip]="'messages.archives' | myTranslate"
      matTooltipPosition="above"
      matTooltipShowDelay="300"
    ></i>
  </div>
</div>
