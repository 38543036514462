
import { Injectable } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Moment } from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../core/providers/assets.service";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { Player } from "../../../../../player/interfaces/player";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { ApiBusinessService } from "../../api/custom/services/api-business.service";
import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { FALLBACK_CURRENCY } from "../../consts/custom/fallback-currency.const";
import { MINIMUM_TYPE } from "../../consts/custom/minimum-type.const";
import { TASK_TYPE } from "../../consts/custom/task-type.const";
import { VALUE_TYPE } from "../../consts/custom/value-type.const";
import { CardTask } from "../../interfaces/custom/card-task.interface";
import { Task } from "../../interfaces/custom/task.interface";

@Injectable()
export abstract class AbstractCardTaskDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractCardTaskDetailsComponent>;
  @OwInject(MAT_DIALOG_DATA) data: {
    cardTask: CardTask;
    task?: Task;
    cardTasks?: CardTask[];
    showOtherClose?: boolean;
    player?: Player;
    backButton?: boolean;
    hideChooseTasksButtons?: boolean;
    momentDate: Moment;
  };

  abstract dialogService: DialogService;
  VALUE_TYPE = VALUE_TYPE;
  MINIMUM_TYPE = MINIMUM_TYPE;
  task: Task;
  cardTask: CardTask;
  cardTasks: CardTask[];
  notEmptyCardTasks: any[] = [];
  currentIndex: number;
  STOCK_VIEW = STOCK_VIEW;
  TASK_TYPE = TASK_TYPE;
  FALLBACK_CURRENCY = FALLBACK_CURRENCY;
  CARD_TEMPLATE = CARD_TEMPLATE;
  expandPrizes: boolean;

  setCurrentIndex() {
    this.currentIndex = this.notEmptyCardTasks.indexOf(this.cardTask);
  }

  setTask(task) {
    if (task.card_template === CARD_TEMPLATE.S1) {
      this.expandPrizes = task.scopes.length > 5 && task.scopes.some(t => t.currency_prizes.length > 1);
    }
    this.task = task;
  }

  setCardTask(cardTask: CardTask) {
    this.cardTask = cardTask;
  }

  changeCardTask(cardTask: CardTask) {
    this.setCardTask(cardTask);
    this.setTask(cardTask.selected);
    this.setCurrentIndex();
  }

  next() {
    let index = 0;
    if (this.currentIndex < this.notEmptyCardTasks.length - 1) {
      index = this.currentIndex + 1;
    }
    this.changeCardTask(this.notEmptyCardTasks[index]);
  }

  prev() {
    let index = this.notEmptyCardTasks.length - 1;
    if (this.currentIndex > 0) {
      index = this.currentIndex - 1;
    }
    this.changeCardTask(this.notEmptyCardTasks[index]);
  }

  openCurve() {
    this.dialogService.openAlertImage({
      image: this.assetsService.getAssetPath("ui/curve.png"),
    });
  }
}
