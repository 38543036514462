import { GAME_CONFIG } from "../config/custom/_parsed-game.config";
import { OwDate } from "./ow-date.class";

export const START_PAGINATION = GAME_CONFIG.START_PAGINATION;

export class OwPaginationDate implements OwPaginationDateI {
  allowNext = {
    month: false,
    quarter: false,
    half: false,
  };

  allowPrev = {
    month: false,
    quarter: false,
    half: false,
  };

  current: OwDate;
  start: OwDate;
  end: OwDate;

  constructor(input: { current?: OwDate; start?: OwDate; end?: OwDate } = {}) {
    // console.log(START_PAGINATION);
    const { current, start, end } = input;

    this.setCurrent(current);
    this.setStart(start);
    this.setEnd(end);

    this.checkMonth();
    this.checkQuarter();
    this.checkHalf();

    this.getAllowPagination();
  }

  setCurrent(current: OwDate) {
    this.current = current || new OwDate();
  }

  setStart(start: OwDate) {
    if (!start) {
      start = new OwDate(START_PAGINATION);
    }

    this.start = start;
  }

  setEnd(end: OwDate) {
    if (!end) {
      end = new OwDate();
    }

    this.end = end;
  }

  checkMonthPrev() {
    const diffMonth = this.current.calcMonth() - this.start.calcMonth();
    this.allowPrev.month = diffMonth > 0;
  }

  checkMonthNext() {
    const diffMonth = this.current.calcMonth() - this.end.calcMonth();
    this.allowNext.month = diffMonth < 0;
  }

  checkMonth() {
    this.checkMonthPrev();
    this.checkMonthNext();
  }

  checkQuarterPrev() {
    const diff = this.current.calcQuarter() - this.start.calcQuarter();
    this.allowPrev.quarter = diff > 0;
  }

  checkQuarterNext() {
    const diff = this.current.calcQuarter() - this.end.calcQuarter();
    this.allowNext.quarter = diff < 0;
  }

  checkQuarter() {
    this.checkQuarterPrev();
    this.checkQuarterNext();
  }

  checkHalfPrev() {
    const diff = this.current.calcHalf() - this.start.calcHalf();
    this.allowPrev.half = diff > 0;
  }

  checkHalfNext() {
    const diff = this.current.calcHalf() - this.end.calcHalf();
    this.allowNext.half = diff < 0;
  }

  checkHalf() {
    this.checkHalfPrev();
    this.checkHalfNext();
  }

  getAllowPagination() {
    return {
      allowNext: this.allowNext,
      allowPrev: this.allowPrev,
    };
  }
}

export interface OwPagination {
  allowNext: {
    month?: boolean;
    quarter?: boolean;
  };

  allowPrev: {
    month?: boolean;
    quarter?: boolean;
  };
}

interface OwPaginationDateI extends OwPagination {
  current: OwDate;
  start: OwDate;
  end: OwDate;
}
